module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'bin2dec';
    const params = {
      0: ['string', '110010', '10', '1', '101010', '110011'],
    };
    const correct = [50, 2, 1, 42, 51];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
