<template>
  <div>
    <Loading :loading="loading" />
    <div
      v-if="!loading && !unauthorized"
      class="container box-container"
    >
      <div class="userprofile-page">
        <div class="head-container">
          <div class="top-infos">
            <!-- <img
							src="@/assets/img/user-black.svg"
							class="profile-img"
							alt="Lined face"
						/> -->
            <Circle
              :xp="user.xp"
              background="diff"
              width="150"
            />
            <div class="profile-info">
              <h1>
                {{ user.username
                }}<span
                  v-if="user.verified"
                  class="verified-user"
                ><img
                  src="@/assets/img/verified.svg"
                  alt="check icon"
                  :title="user.username + ' is a verified user'"
                ></span>
              </h1>
              <p>{{ user.bio }}</p>
            </div>
          </div>

          <div class="secondary-infos">
            <p><b>Joined: </b>{{ user.registered }}</p>

            <div
              v-if="user.website || user.twitter || user.github"
              class="contact-infos"
            >
              <a
                v-if="user.website"
                :href="user.website"
                target="_blank"
                :title="'Check ' + user.username + '\'s website'"
              ><img
                src="@/assets/img/world-wide-web.svg"
                alt="website icon"
              ></a>
              <a
                v-if="user.twitter"
                :href="'https://twitter.com/' + user.twitter"
                :title="'Check ' + user.username + '\'s Twitter profile'"
                target="_blank"
              ><img
                src="@/assets/img/twitter.svg"
                alt="twitter icon"
              ></a>
              <a
                v-if="user.github"
                :href="'https://github.com/' + user.github"
                :title="'Check ' + user.username + '\'s GitHub profile'"
                target="_blank"
              ><img
                src="@/assets/img/github.svg"
                alt="github icon"
              ></a>
            </div>
          </div>
        </div>
        <div class="main-container" />
      </div>
    </div>
    <p
      v-if="!loading && unauthorized"
      class="not-logged-in-info text-center"
    >
      You must be <router-link to="/login">
        logged in
      </router-link> to see
      profile details.
    </p>
    <!-- Ezoic - under_profile - mid_content -->
    <div id="ezoic-pub-ad-placeholder-112" />
    <!-- End Ezoic - under_profile - mid_content -->
    <!-- Ezoic - bottom_of_page - bottom_of_page -->
    <div id="ezoic-pub-ad-placeholder-102" />
    <!-- End Ezoic - bottom_of_page - bottom_of_page -->
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';
import Loading from '@/components/Loading.vue';
import Circle from '@/components/Circle.vue';

export default {
  name: 'UserProfile',
  components: {
    Loading,
    Circle,
  },
  data() {
    return {
      user: {},
      loading: true,
      unauthorized: true,
      progressBarOptions: {
        text: {
          color: '#3282B8',
          shadowEnable: false,
          fontSize: 0,
          fontFamily: 'inherit',
          dynamicPosition: true,
          hideText: true,
        },
        progress: {
          color: '#3282B8',
          backgroundColor:
            this.$store.getters.getUserOption('general_theme') == 'dark'
              ? '#6b6b6b'
              : '#efefef',
        },
        layout: {
          height: 100,
          width: 100,
          zeroOffset: 0,
          strokeWidth: 8,
          type: 'circle',
        },
      },
    };
  },
  async created() {
    const username = this.$route.params['username'];

    // try {
    this.user = await UserService.getUserByUsername(username);

    if (this.user.status === 200) {
      this.user = this.user.data;
      this.unauthorized = false;
      document.title = this.user.username + ' ‒ JSCodebox';

      // set default bio
      if (!this.user.bio) {
        this.user.bio = '404 - Bio not found';
      }

      // set 0 when not done
      if (!this.user.done) {
        this.user.done = 0;
      }
    } else if (this.user.status === 404) {
      // redirect if user does not exists
      this.$router.push('/404');
      return false;
    }

    this.loading = false;
  },
};
</script>
<style lang="css" scoped>
.userprofile-page {
  border-top: 5px solid var(--primary);
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  padding: 3rem;
  background-color: var(--light-grey);
}
.head-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 1rem;
}
.head-container .top-infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.head-container .profile-info {
  max-width: 50%;
}
.head-container .profile-info h1 {
  display: flex;
  align-items: center;
}
.head-container .profile-info p {
  color: var(--grey);
}
.head-container .secondary-infos b {
  color: var(--primary);
}
.head-container .secondary-infos .contact-infos {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 2px;
}
.head-container .secondary-infos .contact-infos img {
  width: 2rem;
  margin: 0 1rem;
}
.head-container .secondary-infos .contact-infos img:first-child {
  margin-left: 0;
}
.head-container .profile-img {
  width: 8rem;
  margin-right: 3rem;
  padding: 1rem;
  background-color: var(--white);
  border: 2px solid var(--primary);
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
}
</style>
