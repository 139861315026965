module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'camelCase';
    const params = {
      0: [
        'string',
        'Camel Case',
        'String not found',
        'Nice Challenge',
        ' Is  not  found ',
        'CamelCase',
      ],
    };
    const correct = [
      'camelCase',
      'stringNotFound',
      'niceChallenge',
      'isNotFound',
      'camelCase',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
