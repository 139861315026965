module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'goldenMiddle';
    const params = {
      0: ['array', [1, 6, 8], [4, 7, 2], [2, 6, 1], [9, 2, 5], [1, 7, 3]],
      1: ['array', [4, 6, 2], [2, 6, 4], [2, 2, 5], [6, 7, 6], [5, 6, 3]],
    };
    const correct = [
      [6, 6],
      [7, 6],
      [6, 2],
      [2, 7],
      [7, 6],
    ];
    const expectedType = ['array', 'number'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
