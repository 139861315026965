'use strict';

const { solve } = require('./solver_1');

module.exports = {
  checkSolution: function (challengeID, iframeDoc, jsCode, bodyContent) {
    // Create a new promise to handle the result of this function
    return new Promise((resolve) => {
      // Get the subsolver associated with the challengeID
      const subsolver = this.getSubsolver(challengeID);

      // Use the subsolver to solve the challenge
      const solveResult = subsolver.solve(iframeDoc, jsCode, bodyContent);

      // Check if the solveResult is a promise object and if it has a then function
      if (solveResult.then && typeof solveResult.then === 'function') {
        // If it is a promise, call the then function
        solveResult.then((result) => {
          // Resolve the promise with the result of handleSubsolveResult
          resolve(this.handleSubsolveResult(result, iframeDoc));
        });
      } else {
        // Otherwise, resolve the promise with the result of handleSubsolveResult
        resolve(this.handleSubsolveResult(solveResult, iframeDoc));
      }
    });
  },
  handleSubsolveResult(solveResult, iframeDoc) {
    switch (solveResult) {
      // Check if the result is '%main-solver%'
      case '%main-solver%':
        // Get elements with class name 'console-solve' and 'console-error'
        let solvings = iframeDoc.getElementsByClassName('console-solve');
        let errors = iframeDoc.getElementsByClassName('console-error');

        // If there are any errors, return false
        if (errors.length) return false;

        // Disable for description line
        let start = 0;

        // Check if the first element contains the required strings
        if (
          solvings[0].getElementsByClassName('tryCase')[0].innerHTML ==
            '<b>Test case</b>' &&
          solvings[0].getElementsByClassName('result')[0].innerHTML ==
            '<b>Your output</b>' &&
          solvings[0].getElementsByClassName('correct')[0].innerHTML ==
            '<b>Expected</b>'
        ) {
          start = 1;
        }

        // If there are no elements, return false
        if (solvings.length - start == 0) return false;

        // Initialize solved variable to 0
        let solved = 0;

        // Loop through all the elements starting from start
        for (let i = start; i < solvings.length; i++) {
          // Store the result and expected values
          var result =
            solvings[i].getElementsByClassName('result')[0].innerHTML;
          var correct =
            solvings[i].getElementsByClassName('correct')[0].innerHTML;

          // If the result and expected values do not match, return false
          if (result != correct) return false;

          // Increment solved variable
          solved++;
        }

        // If the number of solved elements is equal to the number of elements, return true
        if (solved == solvings.length - start) return true;

        break;

      // If the result is not '%main-solver%', return the result
      default:
        return solveResult;
    }
  },
  executeTests: function (challengeID, iframeDoc) {
    // get the subsolver for this challenge
    const subsolver = this.getSubsolver(challengeID);

    // check if there are test cases available
    if (subsolver.SETTINGS && !subsolver.HAS_TEST_CASES) return false;

    // get the test cases
    let testCases = subsolver.getTestCases();
    let testType = testCases.type;
    let payload = testCases.payload;

    // check if the test type is a function
    if (testType == 'function') {
      // check if function execution is only done manually in custom solver
      if (payload.functionBehavior == 'NO_EXECUTION') return false;

      // create a challenge code element
      let challengeCode = document.createElement('script');
      challengeCode.innerHTML =
        'document.addEventListener(\'DOMContentLoaded\', function() {' +
        payload.functionName +
        '(); });';
      // append the challenge code to the iframe document head
      iframeDoc.head.appendChild(challengeCode);
      return false;
    }

    // from here on testType is 'cases':
    /**
     * functionName = name of the function that should be executed
     * output = show three col test case table
     */
    const {
      functionName,
      params,
      correct,
      expectedType, // array 0: expected type, 1: type of array elements
      output,
    } = payload;

    // output desc for the three cols
    if (output) {
      iframeDoc.write(
        '<script>console.solveMessage(\'<b>Test case</b>\', \'<b>Your output</b>\', \'<b>Expected</b>\');</script>'
      );
    } else {
      iframeDoc.write(
        '<script>document.addEventListener(\'DOMContentLoaded\', function() {'
      );
    }

    // get amount of params
    let paramCount = 0;
    for (let singleParam in params) {
      paramCount++;
    }

    // TEST EXECUTION AREA
    // loop through all the test cases
    for (let i = 1; i < params[0].length; i++) {
      let paramString = '';
      let paramStringForOutput = ''; // same as paramString but with different formatting for output
      let expectedString = '';

      // build param string
      for (let j = 0; j < paramCount; j++) {
        let paramType = params[j][0];

        // determine the parameter type and add it to the param string
        switch (paramType) {
          case 'string':
            paramString += '\'' + params[j][i] + '\'';
            paramStringForOutput += '\'' + params[j][i] + '\'';
            break;
          case 'json':
            paramString += params[j][i];
            paramStringForOutput += params[j][i];
            break;
          case 'object':
            paramString += JSON.stringify(params[j][i]);
            paramStringForOutput += JSON.stringify(params[j][i]);
            break;
          case 'array':
            paramString += '[' + params[j][i] + ']';
            paramStringForOutput += '[' + params[j][i] + ']';
            break;
          case 'array-of-arrays':
            let arrOfArrs = [];
            for (let k = 0; k < params[j][i].length; k++) {
              arrOfArrs += '[' + params[j][i][k] + '],';
            }
            arrOfArrs = arrOfArrs.substring(0, arrOfArrs.length - 1);
            paramString += '[' + arrOfArrs + ']';
            paramStringForOutput += '[' + arrOfArrs + ']';
            break;
          case 'integer':
            paramString += params[j][i];
            paramStringForOutput += params[j][i];
            break;
          case 'float':
            paramString += params[j][i];
            paramStringForOutput += params[j][i];
            break;
          case 'boolean':
            paramString += params[j][i];
            paramStringForOutput += params[j][i];
            break;
        }

        // add a comma after each parameter except the last one
        if (j + 1 < paramCount) paramString += ', ';
        if (j + 1 < paramCount) paramStringForOutput += ',<br>&nbsp;&nbsp;';
      }

      // builds the column "Expected"
      switch (expectedType[0]) {
        case 'array':
          if (expectedType[1] == 'string') {
            let innerRes = '';
            let innerArrLength = correct[i - 1].length;
            for (let j = 0; j < innerArrLength; j++) {
              innerRes += '\'' + correct[i - 1][j] + '\',';
            }
            innerRes = innerRes.substring(0, innerRes.length - 1);
            expectedString = `"[${innerRes}]"`;
          } else {
            expectedString = `"[${correct[i - 1]}]"`;
          }
          break;
        case 'array-of-arrays':
          let arr = '';
          for (let a = 0; a < correct[i - 1].length; a++) {
            let innerArr = correct[i - 1][a];
            let innerArrStr = '[';
            for (let b = 0; b < innerArr.length; b++) {
              innerArrStr += innerArr[b] + ',';
            }
            innerArrStr = innerArrStr.substring(0, innerArrStr.length - 1);
            innerArrStr += ']';
            arr += innerArrStr + ',';
          }
          arr = arr.substring(0, arr.length - 1);
          expectedString = `"[${arr}]"`;
          break;
        case 'integer':
          expectedString = `${correct[i - 1]}`;
          break;
        case 'float':
          expectedString = `${correct[i - 1]}`;
          break;
        case 'json':
          expectedString = `'&apos;${correct[i - 1]}&apos;'`;
          break;
        case 'boolean':
          expectedString = `${correct[i - 1]}`;
          break;
        case 'string':
          expectedString = `'&apos;${correct[i - 1]}&apos;'`;
          break;
        case 'string-boolean':
          if (correct[i - 1] == true || correct[i - 1] == false) {
            expectedString = `${correct[i - 1]}`;
          } else {
            expectedString = `'&apos;${correct[i - 1]}&apos;'`;
          }
          break;
        case 'object':
          expectedString = `'${JSON.stringify(correct[i - 1]).replaceAll(
            ',',
            ',<br>'
          )}'`;
          break;
      }

      // build testCase
      let testCaseString = '';
      if (paramStringForOutput) {
        testCaseString = `\`${functionName}(<br>&nbsp;&nbsp;${paramStringForOutput}<br>)\``;
      } else {
        testCaseString = `\`${functionName}()\``;
      }

      // make it one line if very short
      if (testCaseString.length < 60 && functionName.length < 20) {
        // remove all line breaks and &nbsp;
        testCaseString = testCaseString.replaceAll('<br>', '');
        testCaseString = testCaseString.replaceAll('&nbsp;', '');
      }

      // build user output with function
      let usersOutputString = `${functionName}(${paramString})`;

      // write to iframe
      if (output) {
        iframeDoc.write(
          '<script>' +
            `console.solveMessage(${testCaseString}, ` +
            `${usersOutputString}, ` +
            `${expectedString});` +
            '</script>'
        );
      } else {
        iframeDoc.write(`${usersOutputString}; `);
      }
    }

    // close the script tag if output is false
    if (!output) iframeDoc.write('});</script>');
  },
  // This function is used to retrieve the code needed to prepare for a given challenge
  getChallengePrepareCode: function (challengeID, iframeDoc) {
    // Get the subsolver for the given challengeID
    const subsolver = this.getSubsolver(challengeID);

    // Get the preChallengeCode from the subsolver
    let preChallengeCode = subsolver.getChallengePrepareCode
      ? subsolver.getChallengePrepareCode()
      : '';
    // If there is no preChallengeCode then return an empty string
    if (!preChallengeCode) return '';
    // Otherwise, return the preChallengeCode wrapped in document.write()
    return 'document.write(\'' + preChallengeCode + '\')';
  },
  // This function is used to get the subsolver for a given challengeID
  getSubsolver(challengeID) {
    // Require the solver_[challengeID].js file
    return require('../challenge_solver/solver_' + challengeID + '.js');
  },
  // This function is used to get the challenge function for a given challengeID and challengeCode
  getChallengeFunction(challengeID, challengeCode) {
    // Get the subsolver for the given challengeID
    const subsolver = this.getSubsolver(challengeID);
    // Check if wrap is needed
    let wrap =
      subsolver.SETTINGS &&
      'EXEC_CODE' in subsolver.SETTINGS != '' &&
      !subsolver.SETTINGS.EXEC_CODE
        ? 'wrap'
        : '';
    // If wrap is needed, wrap the challengeCode with a function declaration
    if (wrap) {
      challengeCode = 'function ' + wrap + '() {' + challengeCode + '}';
    }
    // Return the challengeCode
    return challengeCode;
  },
};
