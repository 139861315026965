module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'cloneObject';
    const params = {
      0: [
        'object',
        { apples: 7, bananas: 42, citrones: 20, sausages: 2 },
        {
          name: 'Iron Man',
          age: 42,
          superPower: 'None',
          friends: 0,
          overweight: true,
          hungry: true,
        },
        { a: 1, z: 25, h: 8, i: 9, d: 4, u: 20, p: 15 },
      ],
      1: ['array', ['"sausages"'], ['"overweight"', '"hungry"'], []],
    };
    const correct = [
      { apples: 7, bananas: 42, citrones: 20 },
      {
        name: 'Iron Man',
        age: 42,
        superPower: 'None',
        friends: 0,
      },
      { a: 1, z: 25, h: 8, i: 9, d: 4, u: 20, p: 15 },
    ];
    const expectedType = ['object'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
