module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'buildingACar';
    const params = {
      0: ['', ''],
    };
    const correct = [{ wheels: 4, power: 100, doors: 3 }];
    const expectedType = ['object'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
