module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        let h1s = iframeDoc.getElementsByTagName('h1');
        resolve(
          h1s[0] &&
            h1s[0].innerHTML &&
            h1s[0].innerHTML == 'JSCodebox' &&
            h1s[1] &&
            h1s[1].innerHTML &&
            h1s[1].innerHTML == 'New H1 created with JavaScript'
        );
      }, 0);
    });
  },
  getTestCases: function () {
    const functionName = 'printHeadline';
    const params = {
      0: ['string', 'JSCodebox', 'New H1 created with JavaScript'],
    };
    const correct = [];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: false },
    };
  },
};
