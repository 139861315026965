<template>
  <div class="container mail-confirm-page">
    <h1>
      Mail confirmation
    </h1>
    <h3 :class="color">
      {{ message }}
    </h3>

    <router-link
      class="btn green"
      title="Start"
      :to="{
        name: 'challengelist',
      }"
    >
      <img
        src="@/assets/img/rocket-launch-lines.svg"
        alt="Start coding"
      ><span>Start coding!</span>
    </router-link>
  </div>
</template>
<script>
import UserService from '@/services/UserService';

export default {
  data() {
    return {
      message: 'Please wait...',
      color: '',
    };
  },
  async created() {
    try {
      let data = await UserService.confirmEmail(this.$route.params.token);
      this.color = 'green';
      this.message = data.msg;

      // update local user object
      this.$store.dispatch('getUser');
    } catch (err) {
      this.message = err.response.data.msg;
      this.color = 'red';
    }
  },
};
</script>
<style lang="css">
.mail-confirm-page h3.red {
  color: var(--red);
}
.mail-confirm-page h3.green {
  color: var(--green);
}
.mail-confirm-page .btn {
  margin-top: 3rem;
  display: flex !important;
  align-items: center;
  width: 14rem;
  justify-content: center;
  font-weight: 600;
}
.mail-confirm-page .btn img {
  width: 1.6rem;
  margin-right: 1rem;
}
</style>
