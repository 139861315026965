<template>
  <div class="toast-content">
    <span>Looks like you stuck. Here's a hint for you.</span>
    <span class="toast-btn" @click="clicked">show</span>
  </div>
</template>
<script>
export default {
  emits: ['showHintTabClick'],
  methods: {
    clicked() {
      this.$emit('showHintTabClick');
    },
  },
};
</script>
<style scoped>
.toast-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toast-btn {
  border: 1px solid var(--white);
  padding: 5px 12px;
  margin-left: 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
}

.toast-btn:hover {
  border-color: transparent;
  color: var(--primary);
  background-color: var(--white);
}
</style>
