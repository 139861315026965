<template>
  <div class="category-changer">
    <div class="categories">
      <router-link
        v-for="(category, index) in categories"
        :key="index"
        :to="'/category/' + category.categoryPermalink"
        class=""
      >
        #{{ category.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ['categories'],
};
</script>
<style scoped>
.category-changer {
  display: flex;
  margin-top: 2rem;
  justify-content: end;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 50%;
}

.categories a {
  border: 1px solid var(--grey);
  padding: 5px 10px;
  margin-left: 0.7rem;
  margin-bottom: 0.7rem;
  color: var(--grey);
  font-size: 16px;
  border-radius: var(--border-radius);
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
}
.categories a:hover {
  text-decoration: none;
  border-color: var(--primary);
  color: var(--primary);
}
</style>
