module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'uniquePalindromeSubstrings';
    const params = {
      0: [
        'string',
        'abcbabcba',
        'racecar',
        'abcd',
        'maoam',
        'pullupifipullup',
        'tenet',
      ],
    };
    const correct = [
      ['a', 'abcba', 'abcbabcba', 'b', 'bab', 'bcb', 'bcbabcb', 'c', 'cbabc'],
      ['a', 'aceca', 'c', 'cec', 'e', 'r', 'racecar'],
      ['a', 'b', 'c', 'd'],
      ['a', 'aoa', 'm', 'maoam', 'o'],
      [
        'f',
        'i',
        'ifi',
        'l',
        'll',
        'llupifipull',
        'lupifipul',
        'p',
        'pifip',
        'pullup',
        'pullupifipullup',
        'u',
        'ullu',
        'ullupifipullu',
        'upifipu',
      ],
      ['e', 'ene', 'n', 't', 'tenet'],
    ];
    const expectedType = ['array', 'string'];

    return {
      type: 'cases',
      payload: {
        functionName,
        params,
        correct,
        expectedType,
        output: true,
      },
    };
  },
};
