import axios from 'axios';

const url = '/api/essential/';

export default {
  getHeaderNotification(data) {
    return axios
      .get(url + 'headerNotification', data)
      .then((response) => response.data);
  },
  reportBug(data) {
    return axios
      .post(url + 'reportBug', data)
      .then((response) => response.data);
  },
  submitChallengeIdea(data) {
    return axios
      .post(url + 'submitChallengeIdea', data)
      .then((response) => response.data);
  },
};
