module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'arrayManipulation';
    const params = {
      0: ['integer', 5, 4, 10, 3, 2],
      1: [
        'array-of-arrays',
        [
          [1, 2, 100],
          [2, 5, 100],
          [3, 4, 100],
        ],
        [
          [2, 3, 603],
          [1, 1, 286],
          [4, 4, 882],
        ],
        [
          [1, 5, 3],
          [4, 8, 7],
          [6, 9, 1],
        ],
        [
          [1, 3, 5],
          [2, 2, 2],
          [3, 3, 2],
        ],
        [
          [1, 2, 1],
          [1, 2, 2],
        ],
      ],
    };
    const correct = [200, 882, 10, 7, 3];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
