<template>
  <div class="container about">
    <h1>About JSCodebox</h1>

    <h2>How to use JSCodebox</h2>
    <div class="info-category">
      <div class="info-entry">
        <h3>Difficulty levels</h3>
        <p>
          The challenges of JSCodebox are divided into three difficulty levels.
          'Easy', 'Normal' and 'Hard'. The best way is to try out which
          difficulty level suits your level. This is individual for each
          developer.
        </p>
      </div>

      <div class="info-entry">
        <h3>Categories</h3>
        <p>
          All challenges are assigned to one category. This makes it a bit
          easier to learn different programming concepts. More difficult topics,
          such as recursion, are not taught until later levels of difficulty.
        </p>
      </div>

      <div class="info-entry">
        <h3>User solutions</h3>
        <p>
          After you've solved a challenge, solutions from other JSCodebox users
          will be display. Check them out and connect to them if you have
          questions to their code.
        </p>
        <p>
          Your solution will be automatically added here if you are logged in
          and successfully solve the challenge.
        </p>
      </div>

      <div class="info-entry">
        <h3>User comments</h3>
        <p>
          Every registered user can comment on the challenges. These are
          intended for tips or comments for other users.
        </p>
        <p>
          Non-registered users can still read all comments, without exception.
        </p>
      </div>

      <div class="info-entry">
        <h3>Experience (XP)</h3>
        <p>
          When you solve a challenge, you get a certain number of XP for it. In
          the category <b>Easy</b> there are 10 XP, in the category
          <b>Normal</b> 20 XP and in the category <b>Hard</b> 30 XP.
        </p>
        <p>
          These XP can be found on your profile page and are a factor in the
          ranking, which you can find
          <router-link to="/ranking">
            here
          </router-link>.
        </p>
        <p>
          XP should be a sign of how active and familiar you are with
          JavaScript.
        </p>
      </div>

      <div class="info-entry">
        <h3>Level</h3>
        <p>
          Your level is calculated based on the number of credits you have and a
          magic formula ✨. The more tasks you solve, the higher your level will
          be.
        </p>
      </div>

      <div class="info-entry">
        <h3>Ranking</h3>
        <p>
          In the ranking there are two categories in which the top 30 users of
          JSCodebox are listed. This should encourage you to solve further
          challenges and improve your skills.
        </p>
      </div>

      <div class="info-entry">
        <h3>Profile</h3>
        <p>
          On your profile page other users will find all information about you.
          Name, credits, solved tasks and contact information. This information
          is accessible to all users (even those who are not logged in).
        </p>
        <p>
          Only users who are logged in can create such a profile page.
          <b>This is to increase the community of developers and to make new
            contacts.</b>
        </p>
      </div>
    </div>

    <!-- Ezoic - longest_content - longest_content -->
    <div id="ezoic-pub-ad-placeholder-109" />
    <!-- End Ezoic - longest_content - longest_content -->

    <h2>Infos about JSCodebox</h2>
    <div class="info-category">
      <div class="info-entry">
        <h3>What is JSCodebox?</h3>
        <p>
          JSCodebox is a platform for learning JavaScript. There are challenges
          for beginners, advanced users as well as for professionals to
          challenge you. The challenges are also divided into different
          categories, e.g. strings, arrays and recursion.
        </p>
        <p>
          You determine the degree of difficulty yourself and do what you want!
          😇
        </p>
      </div>
      <div class="info-entry">
        <h3>Is it free? 💵</h3>
        <p>
          <b>Yes!</b> JSCodebox is and remains completely free of charge.
          <b>Knowledge should have no price today and be accessible for
            everyone!</b>
        </p>
      </div>
      <div class="info-entry">
        <h3>Do I need a user account?</h3>
        <p>
          <b>No!</b> You can complete <u>all</u> challenges without a user
          account and have the complete range of functions.
        </p>
        <p>
          Nevertheless there is the possibility to create a user account with
          your e-mail address. The code of your challenges and other settings
          will be saved, so that you can easily start programming anytime and
          anywhere.
        </p>
      </div>
      <div class="info-entry">
        <h3>Can I save my progress?</h3>
        <p>
          <b>Yes!</b> There are two possibilities. Either you just start
          programming. Then your data will only be stored temporarily in your
          cache. If you register for free, your data will be stored in a
          database.
        </p>
        <p>
          The code of the challenges, their status and other settings, such as
          your chosen editor theme, are saved in both variants.
        </p>
        <p>
          <b>Note: If you are not logged in, your progress will be lost if you
            clear your cache.</b>
        </p>
      </div>
      <div class="info-entry">
        <h3>How can I support this project?</h3>
        <p>
          You can support us by recommending this site to other developers and
          thereby increase our reach.
        </p>
        <p>
          We also need a lot of
          <router-link to="submit-challenge">
            challenge ideas
          </router-link>,
          <router-link to="bug-report">
            bug reports
          </router-link> and other
          suggestions for improvement. You can send us these via the linked
          forms.
        </p>
      </div>
      <div class="info-entry">
        <h3>Icons</h3>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/eucalyp"
            title="Eucalyp"
          >Eucalyp</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
          is licensed by
          <a
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
          >CC 3.0 BY</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/smashicons"
            title="Smashicons"
          >Smashicons</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
          is licensed by
          <a
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
          >CC 3.0 BY</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/gregor-cresnar"
            title="Gregor Cresnar"
          >Gregor Cresnar</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/freepik"
            title="Freepik"
          >Freepik</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/srip"
            title="srip"
          >srip</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/pixel-perfect"
            title="Pixel perfect"
          >Pixel perfect</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
        </p>
        <p>
          Icons made by
          <a
            href="https://www.flaticon.com/authors/fragneel"
            title="Fragneel"
          >Fragneel</a>
          from
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
          >www.flaticon.com</a>
          is licensed by
          <a
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
          >CC 3.0 BY</a>
        </p>
      </div>
      <!-- Ezoic - incontent_6 - incontent_6 -->
      <div id="ezoic-pub-ad-placeholder-110" />
      <!-- End Ezoic - incontent_6 - incontent_6 -->
    </div>
    <!-- Ezoic - bottom_of_page - bottom_of_page -->
    <div id="ezoic-pub-ad-placeholder-102" />
    <!-- End Ezoic - bottom_of_page - bottom_of_page -->
  </div>
</template>
<style lang="css" scoped>
.about .info-category {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about .info-category .info-entry {
  width: calc(33% - 2rem);
}
</style>
