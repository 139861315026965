module.exports = {
  SETTINGS: {
    HAS_TEST_CASES: false,
  },
  solve: function(iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function() {
        resolve(
          bodyContent === '<p class="console-log">Hello World</p>' ||
            bodyContent === '<p class="console-log">Hello world</p>' ||
            bodyContent === '<p class="console-log">hello world</p>'
        );
      }, 0);
    });
  },
};
