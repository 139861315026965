module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'repeater';
    const params = {
      0: ['string', 'JSCodebox', 'Foobar', '', 'Hello coder!', 'Hi'],
      1: ['integer', 6, 2, 2, 5, 3],
    };
    const correct = [
      'JSCodeJSCodJSCoJSCJSJ',
      'FoF',
      '',
      'HelloHellHelHeH',
      'HiHiH',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
