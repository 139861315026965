<template>
  <div class="container">
    <Loading :loading="loading" />
    <div
      v-if="!loading"
      class="content"
    >
      <h1>{{ category.name }}</h1>
      <div class="challenge-list-container align-center">
        <div
          v-if="category.description"
          class="description"
        >
          <div v-html="category.description" />

          <!-- Ezoic - under_first_paragraph - under_first_paragraph -->
          <div id="ezoic-pub-ad-placeholder-113" />
          <!-- End Ezoic - under_first_paragraph - under_first_paragraph -->

          <!-- Ezoic - mid_content - mid_content -->
          <div id="ezoic-pub-ad-placeholder-105" />
          <!-- End Ezoic - mid_content - mid_content -->

          <!-- Ezoic - bottom_of_page - bottom_of_page -->
          <div id="ezoic-pub-ad-placeholder-102" />
          <!-- End Ezoic - bottom_of_page - bottom_of_page -->
        </div>
        <div class="challenge-box-container no-border auto-width">
          <p
            v-for="(challenge, index) in challenges"
            :key="index"
            class="challenge"
            :class="{ done: challenge.success }"
          >
            <span
              class="check"
              :title="challenge.success ? 'solved' : 'unsolved'"
            />
            <router-link
              title="Start challenge"
              :to="{
                name: 'challenge',
                params: { permalink: challenge['permalink'] },
              }"
            >
              {{ challenge['title'] }}
            </router-link>
          </p>
        </div>
      </div>
      <!-- Ezoic - bottom_of_page - bottom_of_page -->
      <div id="ezoic-pub-ad-placeholder-102" />
      <!-- End Ezoic - bottom_of_page - bottom_of_page -->
    </div>
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';
import CategoryService from '@/services/CategoryService.js';
import Loading from '@/components/Loading.vue';

export default {
  name: 'CategoryView',
  components: { Loading },
  data() {
    return {
      categories: [],
      category: undefined,
      challenges: [],
      loading: true,
    };
  },
  async mounted() {
    this.categories = await CategoryService.getCategories();
    const categorySlug = this.$route.params['category_slug'];

    // fetch current category from all categories
    this.categories.forEach((loopCategory) => {
      if (loopCategory.permalink == categorySlug) {
        this.category = loopCategory;
      }
    });

    // redirect if category does not exists
    if (!this.category) {
      this.$router.push('/404');

      window._paq.push([
        'trackEvent',
        'Debug',
        'Category does not exists',
        'CategoryView',
      ]);
      return false;
    }

    document.title = this.category.name + ' ‒ JSCodebox';

    // load challenges for this category
    this.challenges = await ChallengeService.getChallengesByCategory(
      this.category.id
    );

    // add success state
    this.challenges.forEach((challenge) => {
      if (
        !this.$store.getters.isLoggedIn &&
        localStorage.getItem('challenge-' + challenge.id)
      ) {
        challenge.success = JSON.parse(
          localStorage.getItem('challenge-' + challenge.id)
        ).done;
      } else if (this.$store.getters.getUserChallenge(challenge.id)) {
        challenge.success = this.$store.getters.getUserChallenge(
          challenge.id
        ).done;
      }
    });

    this.loading = false;
  },
};
</script>
<style scoped lang="css">
.description {
  width: calc(70% - 4rem);
}
</style>
