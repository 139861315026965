module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'difference42';
    const params = {
      0: ['integer', 7, 42, 52, 20, 67],
    };
    const correct = [35, 0, 10, 22, 25];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
