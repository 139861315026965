module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'cinemaQueue';
    const params = {
      0: ['integer', 6, 6, 4, 8, 5],
      1: [
        'array',
        ['\'X\'', '\'O\'', '\'X\'', '\'O\'', '\'O\'', '\'X\'', '\'X\'', '\'X\'', '\'O\'', '\'X\''],
        [
          '\'X\'',
          '\'O\'',
          '\'X\'',
          '\'O\'',
          '\'O\'',
          '\'X\'',
          '\'X\'',
          '\'X\'',
          '\'O\'',
          '\'X\'',
          '\'X\'',
          '\'X\'',
        ],
        ['\'X\'', '\'O\'', '\'X\''],
        ['\'X\'', '\'O\'', '\'X\'', '\'X\''],
        ['\'X\'', '\'O\'', '\'X\'', '\'O\'', '\'O\'', '\'X\'', '\'X\'', '\'O\'', '\'X\''],
      ],
    };
    const correct = [
      'full',
      'too much: 2',
      'not full: 2',
      'not full: 5',
      'full',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
