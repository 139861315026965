export default {
  EDITOR_THEMES: [
    'one-dark',
    'eclipse',
    'sublime',
    'tokyoNightDay',
    'tokyoNight',
    'atomone',
    'aura',
    'nord',
    'okaidia',
  ],

  DEFAULT_EDITOR_THEME: 'okaidia',

  EDITOR_LAYOUTS: [
    { id: 'log_in_new_line', label: 'Test cases in new line' },
    { id: 'log_inline', label: 'Test cases and editor in one line' },
  ],

  DEFAULT_EDITOR_LAYOUT: 'log_inline',

  DIFFICULTIES: [
    { number: 1, name: 'Easy', color: 'green' },
    { number: 2, name: 'Medium', color: 'orange' },
    { number: 3, name: 'Hard', color: 'red' },
  ],
};
