module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'solveTicTacToe';
    const params = {
      0: ['array', ['\'XOX\''], ['\'OXO\''], ['\'O-X\''], ['\'OXX\''], ['\'-O-\'']],
      1: ['array', ['\'XXX\''], ['\'XOO\''], ['\'-XO\''], ['\'XOO\''], ['\'-OX\'']],
      2: ['array', ['\'OXO\''], ['\'XOX\''], ['\'OOX\''], ['\'XOO\''], ['\'-O-\'']],
    };
    const correct = [true, 'Tie', 'Tie', true, true];
    const expectedType = ['string-boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
