import { createStore } from 'vuex';
import Axios from 'axios';
import VuexPersistence from 'vuex-persist';
import router from '@/router.js';

import UserService from '@/services/UserService.js';

const getDefaultState = () => {
  return {
    sessionToken: '',
    user: {},
    userOptions: {},
    userChallenges: {},
  };
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = createStore({
  plugins: [vuexLocal.plugin],
  state() {
    return getDefaultState();
  },
  getters: {
    // if a user is logged in
    isLoggedIn(state) {
      return state.sessionToken;
    },
    // returns the users token
    getToken(state) {
      return state.sessionToken ? state.sessionToken : '';
    },
    // returns the whole user object
    getUser(state) {
      return state.user;
    },
    // returns all options of a user
    getUserOptions(state) {
      return state.options;
    },
    // returns the value of a specific option
    getUserOption: (state) => (optionName) => {
      // is not logged in => get from localStorage
      if (!state.userOptions.length) {
        if (localStorage.getItem(optionName))
          return localStorage.getItem(optionName);
        return undefined;
      }

      // if logged in
      let res = state.userOptions.filter((o) => o.kex == optionName);
      if (res.length) return res[0].value;
      return undefined;
    },
    // return a challenges state of a challenge (code, state of the challenge, etc.)
    getUserChallenge: (state) => (challengeID) => {
      // is not logged in => get from localStorage
      if (!store.getters.isLoggedIn) {
        return JSON.parse(localStorage.getItem('challenge-' + challengeID));
      }

      // if logged in
      if (!state.userChallenges) return undefined;
      return state.userChallenges.filter(
        (c) => c.challenge_id == challengeID
      )[0];
    },
    // return all challenges states of a user (code, state of the challenge, etc.)
    getAllUserChallenges: (state) => {
      return state.userChallenges;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_USER_OPTIONS(state, payload) {
      state.userOptions = payload;
    },
    SET_USER_CHALLENGES(state, payload) {
      state.userChallenges = payload;
    },
    SET_TOKEN(state, payload) {
      state.sessionToken = payload;
    },
    RESET(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    getUser: async ({ commit }) => {
      try {
        const user = await UserService.getUser();
        commit('SET_USER', user);
      } catch (err) {
        console.log(err);
      }
    },
    login({ commit, dispatch }, { token }) {
      commit('SET_TOKEN', token);

      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // get necessary data
      dispatch('getUser');
      dispatch('getUserChallenges');
      dispatch('getUserOptions');
    },
    logout({ commit }) {
      commit('RESET', '');

      // refresh route or redirect
      if (router.currentRoute.name == 'challengelist') {
        router.go();
      } else {
        router.push('/');
      }
    },
    async getUserOptions({ commit }) {
      try {
        const response = await UserService.getOptions();
        commit('SET_USER_OPTIONS', response);
      } catch (err) {
        console.log(err);
      }
    },
    getUserChallenges: async ({ commit }) => {
      try {
        const response = await UserService.getChallenges();
        commit('SET_USER_CHALLENGES', response);
      } catch (err) {
        console.log(err);
      }
    },
    checkAuth: async () => {
      try {
        await UserService.checkAuth();
      } catch (err) {
        console.log(err);
      }
    },
    setUserOption: async ({ dispatch }, payload) => {
      // not logged in => write to localStorage
      if (!store.getters.isLoggedIn) {
        localStorage.setItem(payload.kex, payload.value);
        return false;
      }

      // logged in
      try {
        await UserService.setOption(payload);
      } catch (err) {
        console.log(err);
      }

      // update vars
      dispatch('getUserOptions');
    },
    updateChallenge: async ({ dispatch }, payload) => {
      // not logged in => write to localStorage
      if (!store.getters.isLoggedIn) {
        localStorage.setItem(
          'challenge-' + payload.challengeID,
          JSON.stringify(payload)
        );
        return false;
      }

      // is logged in
      try {
        await UserService.setChallenge(payload);
      } catch (err) {
        console.log(err);
      }

      // update vars
      dispatch('getUserChallenges');
      dispatch('getUser');
    },
  },
});
export default store;
