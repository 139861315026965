module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'gHappy';
    const params = {
      0: [
        'string',
        'xyggxyz',
        'Huggy Wuggy',
        'xxzzgxxzz',
        'garage of eggs',
        'yyzzgxxzzggzzy',
        'Not bigGgame!',
        '',
        'g',
        'gg',
        'egg',
        'BIGger!',
        'The bigggest egggame!',
        'garage',
      ],
    };
    const correct = [
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      true,
      false,
    ];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
