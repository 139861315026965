module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'http2https';
    const params = {
      0: [
        'string',
        'http://jscodebox.com/',
        'http://jscodebox.com/',
        'http://jscodebox.com/index.php?id=2',
        'http://jscodebox.com:80/home',
        'http://jscodebox.com:80/home?admin=1&test=0',
      ],
    };
    const correct = [
      'https://jscodebox.com/',
      'https://jscodebox.com/',
      'https://jscodebox.com/index.php?id=2',
      'https://jscodebox.com:80/home',
      'https://jscodebox.com:80/home?admin=1&test=0',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
