module.exports = {
  SETTINGS: {
    HAS_TEST_CASES: false,
  },
  solve: function(iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function() {
        let script = document.createElement('script');
        script.innerHTML = jsCode + 'checkMe();';
        if (iframeDoc.head.lastChild) iframeDoc.head.lastChild.remove();
        iframeDoc.head.appendChild(script);

        resolve(
          iframeDoc.getElementById('my-checkbox') &&
            iframeDoc.getElementById('my-checkbox').checked
        );
      }, 0);
    });
  },
  getChallengePrepareCode: function() {
    return '<input type="checkbox" id="my-checkbox" disabled><label for="my-checkbox" name="my-checkbox">Hey there, check me!</label>';
  },
};
