<template>
  <h1>
    {{ challenge.title }}
  </h1>
</template>
<script>
export default {
  props: ['challenge'],
};
</script>
