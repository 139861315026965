module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        resolve(
          bodyContent.indexOf('<p class="container">Container</p>') > -1 &&
            bodyContent.indexOf('<p class="wrapper">Wrapper</p>') > -1 &&
            bodyContent.indexOf('<p class="class">Class</p>') > -1 &&
            bodyContent.indexOf('<p class="foobar">Foobar</p>') > -1 &&
            bodyContent.indexOf('<p class="42">42</p>') > -1
        );
      }, 10);
    });
  },
  getTestCases: function () {
    const functionName = 'welcomeToMyClass';
    const params = {
      0: ['string', 'container', 'wrapper', 'class', 'foobar', '42'],
    };
    const correct = [];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: false },
    };
  },
};
