module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'littleChild';
    const params = {
      0: ['integer', 4, 9, 21, 0, 15, -3, -5],
      1: ['integer', 15, 7, 20, 3, 13, 11, -11],
    };
    const correct = [true, false, false, false, true, true, false];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
