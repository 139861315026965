import axios from 'axios';

const url = '/api/user/';

export default {
  login(credentials) {
    return axios
      .post(url + 'login/', credentials)
      .then((response) => response.data);
  },
  register(credentials) {
    return axios.post(url, credentials).then((response) => response.data);
  },
  setOption(data) {
    return axios.post(url + 'option/', data).then((response) => response.data);
  },
  getOptions() {
    return axios.get(url + 'option/').then((response) => response.data);
  },
  getChallenges() {
    return axios.get(url + 'challenges/').then((response) => response.data);
  },
  setChallenge(data) {
    return axios
      .post(url + 'challenge/', data)
      .then((response) => response.data);
  },
  getUser() {
    return axios.get(url).then((response) => response.data);
  },
  getUserByUsername(username) {
    return axios
      .get(url + username)
      .then((response) => response)
      .catch((err) => err.response);
  },
  getXP() {
    return axios.get(url + 'xp/').then((response) => response.data);
  },
  saveUser(data) {
    return axios.put(url, data).then((response) => response.data);
  },
  deleteAccount() {
    return axios.delete(url).then((response) => response.data);
  },
  confirmEmail(token) {
    return axios
      .post(url + 'administration/confirmEmail/', { token })
      .then((response) => response.data);
  },
  sendPasswordLink(email) {
    return axios
      .post(url + 'administration/sendPasswordLink/', { email })
      .then((response) => response.data);
  },
  resetPasswordByToken(token, password, password_repeat) {
    return axios
      .post(url + 'administration/resetPasswordByToken/', {
        token,
        password,
        password_repeat,
      })
      .then((response) => response.data);
  },
  checkToken(token) {
    return axios
      .post(url + 'checkToken/', { token })
      .then((response) => response.data);
  },
  getSolvedChallengesCounting() {
    return axios
      .get(url + 'property/solvedChallengesCounting/')
      .then((response) => response.data);
  },
  getDayStreak() {
    return axios
      .get(url + 'property/getDayStreak/')
      .then((response) => response.data.streak);
  },
  checkAuth() {
    return axios.get(url + 'check-auth/').then((response) => response.data);
  },
};
