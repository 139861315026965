<template>
  <div class="slime-container">
    <span class="slime">
      <span class="eye">
        <span class="in-eye" />
      </span>
      <span class="eye">
        <span class="in-eye" />
      </span>
      <div class="mouth" />

      <span class="shadow" />
    </span>
    <div class="ball" />
  </div>
</template>
<script>
export default {
  name: 'Mascot',
};

var inEyes = document.getElementsByClassName('in-eye');

document.onmousemove = function() {
  const x = (event.clientX * 100) / window.innerWidth + '%';
  const y = (event.clientY * 100) / window.innerHeight + '%';

  for (let i = 0; i < inEyes.length; i++) {
    inEyes[i].style.left = x;
    inEyes[i].style.top = y;

    inEyes[i].style.transform = 'translate(-' + x + ', -' + y + ')';
  }
};
</script>
<style scoped lang="css">
.slime-container {
  display: flex;
  align-items: center;
  height: 20rem;
  position: relative;
  margin-top: 2rem;
}
.ball {
  width: 300px;
  height: 300px;
  /*background-color: rgba(231, 91, 118, 0.2);
	*/
  background-color: rgba(108, 108, 177, 0.2);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.slime {
  /*background-color: #e75b76;
	*/
  background-color: var(--primary);
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  border-radius: 10%;
  -moz-border-radius: 10%;
  -webkit-border-radius: 10%;
  border-top-right-radius: 10% 65%;
  animation: bounce 4s infinite;
}
.shadow {
  position: absolute;
  bottom: -8%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
  animation: opacityChange 4s infinite;
}
.eye {
  width: 70px;
  height: 80px;
  background-color: var(--white);
  position: absolute;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  animation: wink 15s infinite;
}
.eye:nth-child(1n) {
  top: 16%;
  left: 12%;
}
.eye:nth-child(1n) .in-eye {
  top: 30%;
  left: 10%;
}
.eye:nth-child(2n) {
  top: 16%;
  left: 55%;
}
.eye:nth-child(2n) .in-eye {
  top: 30%;
  left: 10%;
}
.eye .in-eye {
  width: 40px;
  height: 40px;
  display: block;
  background-color: #000;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
}
.mouth {
  width: 15%;
  height: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 3px solid transparent;
  border-bottom: 6px solid var(--white);
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes wink {
  2%,
  4%,
  20%,
  22%,
  50%,
  52%,
  53%,
  55% {
    height: 80px;
    opacity: 1;
  }
  3%,
  21%,
  51%,
  54% {
    height: 0;
    opacity: 0;
  }
}
@keyframes opacityChange {
  0% {
    box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    width: 60%;
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.8);
    width: 65%;
    bottom: -6%;
  }
  100% {
    box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.6);
    width: 60%;
  }
}
</style>
