import axios from 'axios';

const url = '/api/xp/';

export default {
  // needs to be called once per component before using other methods of this service!
  async load() {
    try {
      let response = await axios
        .get(url + 'levelLimits/')
        .then((response) => response.data);
      this.levels = response;
    } catch (err) {
      console.log(err);
    }
  },
  getLevelLimits() {
    return axios.get(url + 'levelLimits/').then((response) => response.data);
  },
  getNextLevelBorder(xp) {
    for (let i = this.levels.length - 1; i >= 0; i--) {
      if (xp >= this.levels[i]) return this.levels[i + 1];
    }
    return this.levels[0];
  },
  getPreLevelBorder(xp) {
    for (let i = this.levels.length - 1; i >= 0; i--) {
      if (xp >= this.levels[i]) return this.levels[i];
    }
    return 0;
  },
  getLevel(xp) {
    for (let i = this.levels.length - 1; i >= 0; i--) {
      if (xp >= this.levels[i]) return i + 2;
    }
    return 1;
  },
};
