module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'countOdds';
    const params = {
      0: [
        'array',
        [1, 5, 2, 6, 5, 3, 9, 2],
        [2, 6, 2, 5, 2, 8],
        [1, 1],
        [0],
        [6, 3, 8, 2],
      ],
    };
    const correct = [5, 1, 2, 0, 1];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
