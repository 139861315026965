module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'dec2bin';
    const params = {
      0: ['integer', 50, 2, 1, 42, 51],
    };
    const correct = ['110010', '10', '1', '101010', '110011'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
