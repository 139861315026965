module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'circularRepeat';
    const params = {
      0: ['string', 'abc', '123', 'hello', 'world', 'xyz', 'abcd'],
      1: ['integer', 3, 5, 2, 4, 10, 100],
    };
    const correct = [
      'abcabcabc',
      '123123123123123',
      'hellohello',
      'worldworldworldworld',
      'xyzxyzxyzxyzxyzxyzxyzxyzxyzxyz',
      'abcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcdabcd',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
