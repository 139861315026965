<template>
  <div class="challenge-view-with-sidebar">
    <div class="left-sidebar">
      <!-- Ezoic - sidebar_middle_left - sidebar_middle -->
      <div id="ezoic-pub-ad-placeholder-114" />
      <!-- End Ezoic - sidebar_middle_left - sidebar_middle -->
    </div>

    <div class="challenge fade-in-container" :class="{ fadeIn: !loading }" :data-challenge-id="challenge.id">
      <Loading :loading="loading" />
      <!-- Ezoic - top_of_page - top_of_page -->
      <div id="ezoic-pub-ad-placeholder-103" />
      <!-- End Ezoic - top_of_page - top_of_page -->

      <ChallengeTitle :challenge="challenge" />

      <!-- Ezoic - under_page_title - under_page_title -->
      <div id="ezoic-pub-ad-placeholder-104" />
      <!-- End Ezoic - under_page_title - under_page_title -->

      <ChallengeInfos
        :description="challenge.description" :example="challenge.example" :resources="challenge.resources"
        :hint="challenge.hint" :done="challenge.done" :challenge-i-d="challenge.id" :category="{
          name: challenge.category_name,
          permalink: challenge.category_permalink,
        }" :difficulty="challenge.difficulty"
      />

      <div
        class="half-windows" :class="[
          {
            disabled: success,
          },
          editorLayout,
        ]"
      >
        <codemirror v-model="challenge.code" :extensions="cmExtensions" :autofocus="true" :tab-size="2" :style="{}" />
        <div class="frame-with-legend">
          <span
            v-if="editorLayout == 'log_inline'" class="toggle" title="resize windows" @mousemove="resize"
            @mousedown="resizeMoveDown = true" @mouseup="resizeMoveDown = false" @mouseleave="resizeMoveDown = false"
          />
          <iframe ref="resultFrame" />
          <CodeColorLegend />
        </div>
      </div>

      <ChallengeBottom
        :editor-layout="editorLayout" :success="success" :show-solution="showSolution"
        :notification="notification" :challenge="challenge" @run-code="runCode" @change-theme="changeTheme"
        @change-editor-layout="changeEditorLayout"
      />

      <!-- Ezoic - under_first_paragraph - under_first_paragraph -->
      <div id="ezoic-pub-ad-placeholder-113" />
      <!-- End Ezoic - under_first_paragraph - under_first_paragraph -->

      <!-- Ezoic - mid_content - mid_content -->
      <div id="ezoic-pub-ad-placeholder-105" />
      <!-- End Ezoic - mid_content - mid_content -->

      <!-- Ezoic - bottom_of_page - bottom_of_page -->
      <div id="ezoic-pub-ad-placeholder-102" />
      <!-- End Ezoic - bottom_of_page - bottom_of_page -->

      <UserArea v-if="$store.getters.isLoggedIn" :challenge-i-d="challenge.id" :show-solutions="showUserSolutions" />
    </div>
    <div class="right-sidebar">
      <!-- Ezoic - sidebar_middle_right - sidebar_middle -->
      <div id="ezoic-pub-ad-placeholder-115" />
      <!-- End Ezoic - sidebar_middle_right - sidebar_middle -->
    </div>
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';
import XPService from '@/services/XPService.js';
import config from '@/config.js';
import { useToast } from 'vue-toastification';

import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';

// import editor styles
import { oneDark } from '@codemirror/theme-one-dark';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { tokyoNightDay } from '@uiw/codemirror-theme-tokyo-night-day';
import { tokyoNight } from '@uiw/codemirror-theme-tokyo-night';
import { atomone } from '@uiw/codemirror-theme-atomone';
import { aura } from '@uiw/codemirror-theme-aura';
import { nord } from '@uiw/codemirror-theme-nord';
import { okaidia } from '@uiw/codemirror-theme-okaidia';

import CodeColorLegend from '@/components/challenge/CodeColorLegend.vue';
import UserArea from '@/components/challenge/UserArea.vue';
import Loading from '@/components/Loading.vue';

import ChallengeInfos from '@/components/challenge/ChallengeInfos.vue';
import ChallengeTitle from '@/components/challenge/ChallengeTitle.vue';
import ChallengeBottom from '@/components/challenge/ChallengeBottom.vue';

export default {
  name: 'ChallengeView',
  components: {
    Codemirror,
    CodeColorLegend,
    UserArea,
    ChallengeInfos,
    Loading,
    ChallengeTitle,
    ChallengeBottom,
  },
  data() {
    return {
      challenge: [],
      result: '',
      loading: true,
      notification: { msg: '', color: '' },
      success: false,
      disabled: true,
      solver: null,
      showSolution: false,
      editorLayout: '',
      iframeDoc: null,
      showUserSolutions: false,
      resizeMoveDown: false,
      lastResizeMoveX: -1,
      cmExtensions: [javascript(), okaidia],
      editorThemes: config.EDITOR_THEMES,
      cmThemes: {
        'one-dark': oneDark,
        eclipse: eclipse,
        sublime: sublime,
        tokyoNightDay: tokyoNightDay,
        tokyoNight: tokyoNight,
        atomone: atomone,
        aura: aura,
        nord: nord,
        okaidia: okaidia,
      },
      toast: useToast(),
      challengeData: {  // some data for the after challenge ad
        tries: 0,
        start: new Date(),
        end: null,
        difficulty: 0
      },
    };
  },
  async created() {
    this.createAds();

    await this.checkPermalink();

    // import specific challenge solver
    this.solver = require('../challenge_solver/MasterSolver.js');
    this.solverUtils = require('../challenge_solver/SolverUtils.js');

    // get iframe
    let iframe = this.$refs.resultFrame;
    let iframeWin = iframe.contentWindow || iframe;
    this.iframeDoc = iframe.contentDocument || iframeWin.document;

    this.runCode(false);

    // set theme
    let editorThemeFromStore =
      this.$store.getters.getUserOption('editor_theme');
    if (
      editorThemeFromStore &&
      this.editorThemes.indexOf(editorThemeFromStore) > -1
    ) {
      this.cmExtensions.pop();
      this.cmExtensions.push(this.cmThemes[editorThemeFromStore]);
    } else {
      this.cmExtensions.pop();
      this.cmExtensions.push(this.cmThemes[config.DEFAULT_EDITOR_THEME]);
    }
    this.updateCodeEditor();

    // set editor layout
    if (this.$store.getters.getUserOption('editor_layout')) {
      this.editorLayout = this.$store.getters.getUserOption('editor_layout');
    } else {
      this.editorLayout = config.DEFAULT_EDITOR_LAYOUT;
    }

    this.loading = false;
  },
  methods: {
    createAds() {
      window.ezstandalone = window.ezstandalone || {};
      ezstandalone.cmd = ezstandalone.cmd || [];
      ezstandalone.cmd.push(function () {
        // console.log(ezstandalone);
        if (ezstandalone.enabled)
          ezstandalone.destroyPlaceholders(102, 103, 104, 105, 109, 113, 114, 115, 119);
        ezstandalone.define(102, 103, 104, 105, 113, 114, 115);
        ezstandalone.refresh();
      });
    },
    updateCodeEditor() {
      // needs to call to update codemirror after theme set
      this.challenge.code = '|' + this.challenge.code;
      let that = this;
      setTimeout(function () {
        that.challenge.code = that.challenge.code.substring(1);
      }, 1);
    },
    // checks the permalink of the challenge and loads the challenge or redirect to 404 page if challenge does not exists
    checkPermalink: async function () {
      return new Promise(async (resolve) => {
        try {
          const permalink = this.$route.params['permalink'];

          if (this.isNumber(permalink)) {
            // if permalink is number => old format => redirect to permalink and return
            this.challenge = await ChallengeService.getChallengeById(
              parseInt(permalink)
            );
            this.$router.push('/challenge/' + this.challenge.permalink);
            return false;
          } else {
            this.challenge = await ChallengeService.getChallengeByPermalink(
              permalink
            );
          }

          document.title =
            'Challenge: ' + this.challenge.title + ' ‒ JSCodebox';

          // add challenge info to ad data
          this.challengeData.difficulty = this.challenge.difficulty;

          // get vars from user
          this.loadUsersChallengeData();
          resolve();
        } catch (err) {
          // console.log(err);
          // redirect if challenge does not exists
          this.$router.push('/404');

          window._paq.push([
            'trackEvent',
            'Debug',
            'Challenge does not exists',
            err,
          ]);
          console.log(err);
          return false;
        }
      });
    },
    // loads the challenge data from the user
    loadUsersChallengeData: function () {
      let userChallenge = this.$store.getters.getUserChallenge(
        this.challenge.id
      );
      if (userChallenge) {
        this.challenge.done = userChallenge.done;
        this.challenge.code = userChallenge.code;
      }
    },
    resize: function (e) {
      if (this.resizeMoveDown) {
        if (this.lastResizeMoveX == -1) this.lastResizeMoveX = e.clientX;

        let editorWidth = document.querySelector(
          '.v-codemirror .cm-editor'
        ).clientWidth;
        let diff = Math.abs(this.lastResizeMoveX - e.clientX);
        if (this.lastResizeMoveX > e.clientX) {
          document.querySelector('.v-codemirror .cm-editor').style.width =
            editorWidth - diff + 'px';
        } else {
          document.querySelector('.v-codemirror .cm-editor').style.width =
            editorWidth + diff + 'px';
        }
        this.lastResizeMoveX = e.clientX;
      }
    },
    runCode: async function (output) {
      // console.log('matomo', this.$matomo);
      this.iframeDoc.open();

      this.solverUtils.writePredefinedHead(this.iframeDoc);

      // execute preCode
      let preChallengeCode = this.solver.getChallengePrepareCode(
        this.challenge.id,
        this.iframeDoc
      );
      if (preChallengeCode) {
        let preChallengeCodeScriptTag = document.createElement('script');
        preChallengeCodeScriptTag.innerHTML = preChallengeCode;
        this.iframeDoc.head.appendChild(preChallengeCodeScriptTag);
      }

      // write users code to iframe
      try {
        let challengeCode = this.solver.getChallengeFunction(
          this.challenge.id,
          this.challenge.code
        );

        this.iframeDoc.write(`<script>${challengeCode}<\/script>`);
      } catch (err) {
        console.log(err);
      }

      // execute solving tests
      this.solver.executeTests(this.challenge.id, this.iframeDoc);

      this.iframeDoc.close();

      const jsCode = this.challenge.code;
      let bodyContent = '';
      if (this.$refs.resultFrame.contentDocument.body)
        bodyContent = this.$refs.resultFrame.contentDocument.body.innerHTML;

      let solveResult = await this.solver.checkSolution(
        this.challenge.id,
        this.iframeDoc,
        jsCode,
        bodyContent
      );

      // no output on page load
      if (!output) return false;

      // only track event if output should be display (so if user has clicked by his self)
      if (output) {
        window._paq.push([
          'trackEvent',
          'Challenge',
          'Run Code Click',
          solveResult ? 'Success' : 'Failed',
        ]);

        this.challengeData.tries++;
      }

      if (solveResult) {
        this.levelUpNotification(this.challenge.done);
        this.showNotification(
          'Awesome! Great job' +
          (this.$store.state.user.username
            ? ' <b>' + this.$store.state.user.username + '</b>'
            : '') +
          '! 👌',
          'green'
        );
        this.success = true;
        this.showUserSolutions = true;
        this.challenge.done = true;
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      } else {
        this.showNotification('Some test cases failed!', 'red');
      }

      // save challenge
      this.$store.dispatch('updateChallenge', {
        challengeID: this.challenge.id,
        done: solveResult ? '1' : '0',
        challenge_difficulty: this.challenge.difficulty,
        code: this.challenge.code,
      });
    },
    isNumber(string) {
      return /^\d+$/.test(string);
    },
    showNotification: function (msg, color) {
      this.notification.msg = msg;
      this.notification.color = color;
    },
    changeTheme(theme) {
      this.cmExtensions.pop();
      this.cmExtensions.push(this.cmThemes[theme]);

      this.$store.dispatch('setUserOption', {
        kex: 'editor_theme',
        value: theme,
      });

      this.updateCodeEditor();
    },
    changeEditorLayout(layoutId) {
      this.editorLayout = layoutId;
      this.$store.dispatch('setUserOption', {
        kex: 'editor_layout',
        value: layoutId,
      });
    },
    /**
     * Called when the next challenge button is clicked
     */
    nextChallengeClicked() {
      this.challengeData.end = new Date();
      this.adVisible = true;
    },
    /**
     * Next challenge button clicked in the popup
     */
    nextChallengeClickedPopup() {
      this.goToNextChallenge();
    },
    /**
     *
     * @param {boolean} challengeDone - if the challenge was done before this try
     */
    async levelUpNotification(challengeDone) {
      if (!this.$store.getters.isLoggedIn) return false;

      await XPService.load();
      // calculate new XP only for messaging
      let addedXP =
        this.challenge.difficulty == 0 ? 1 : this.challenge.difficulty * 10;

      // show xp notification when challenge solved for the first time and logged in
      if (!challengeDone) {
        this.toast.info('+ ' + addedXP + ' XP');
      }

      // show level toast?
      let currentLevel = XPService.getLevel(this.$store.state.user.xp);
      let nextLevel = XPService.getLevel(this.$store.state.user.xp + addedXP);
      // console.log(currentLevel, nextLevel);

      if (currentLevel != nextLevel && !challengeDone) {
        this.toast.success(
          `Congratulations! 🎉 You've reached level ${nextLevel}!`,
          { timeout: 10000 }
        );
      }
    },
    /**
     * Get the next challenge based on all challenges of the current difficulty and the user's progress
     */
    getNextChallenge(nextChallenges) {
      let nextID2Check; // id of the next challenge to check
      let nextChallenge; // (maybe) next challenge
      let endlessLoopCounter = 0; // counter to prevent endless loops

      // begin logic to find the next challenge
      nextID2Check = nextChallenges[0].id; // start with the first challenge

      do {
        // get users challenge of the ID that should be checked
        nextChallenge = this.$store.getters.getUserChallenge(nextID2Check);

        // if the next challenge is not found in the store or is not done => we found the next challenge
        if (!nextChallenge || !nextChallenge.done) {
          // build fake challenge to return
          nextChallenge = {
            id: nextID2Check,
            done: 0,
          };
          break;
        }

        // if the challenge is done => get the next challenge ID to check
        nextID2Check = nextChallenges[endlessLoopCounter + 1].id;

        // increase counter to prevent endless loops
        endlessLoopCounter++;
      } while (
        nextChallenge.done &&
        endlessLoopCounter + 1 < nextChallenges.length
      );

      return nextChallenge;
    },
    goToNextChallenge: async function () {
      // if challenge has a predefined next challenge
      if (this.challenge.nextChallenge) {
        this.switchToNextChallenge(this.challenge.nextChallenge);
        return false;
      }

      // get all challenges from current difficulty
      const nextChallenges = await ChallengeService.getChallengesByDifficulty(
        this.challenge.difficulty
      );

      // random order next challenges
      nextChallenges.sort(() => Math.random() - 0.5);

      //  get next challenge if any available
      if (nextChallenges.length) {
        let nextChallenge = this.getNextChallenge(nextChallenges);

        // if all challenges are done => redirect to finished page
        if (nextChallenge.done) {
          this.$router.push({
            name: 'challengelist',
            query: {
              categoryFinished: this.challenge.difficulty,
            },
          });
        } else {
          // main redirect to next challenge
          this.switchToNextChallenge(nextChallenge.id);
        }
      } else {
        // no available challenges left
        this.$router.push({
          path: '/',
        });
      }
    },
    async switchToNextChallenge(nextChallengeID) {
      let nextChallengeFromServer = await ChallengeService.getChallengeById(
        nextChallengeID
      );
      this.$router.push({
        name: 'challenge',
        params: { permalink: nextChallengeFromServer.permalink },
      });
    },
  },
};
</script>
<style lang="css">
.half-windows.log_inline {
  display: flex;
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  align-items: stretch;
}

.half-windows.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.half-windows.log_inline .toggle {
  position: absolute;
  background-color: var(--grey);
  color: var(--white);
  opacity: 0.2;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  top: 20px;
  width: 10px;
  left: -5px;
  height: calc(100% - 44px);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  cursor: w-resize;
}

.half-windows.log_inline .toggle:after,
.half-windows.log_inline .toggle:before {
  content: '|';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-weight: 200;
  color: var(--white);
  display: none;
}

.half-windows.log_inline .toggle:before {
  left: calc(50% - 3px);
}

.half-windows.log_inline .toggle:after {
  left: calc(50% + 3px);
}

.half-windows.log_inline .toggle:hover {
  opacity: 0.6;
  width: 30px;
  left: -15px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.half-windows.log_inline .toggle:hover:after,
.half-windows.log_inline .toggle:hover:before {
  display: block;
}

.half-windows.log_inline .v-codemirror .cm-editor {
  min-width: 20%;
  width: 50%;
  height: 26rem;
  resize: horizontal;
  overflow: auto;
  outline: none;
}

.half-windows.log_inline .frame-with-legend {
  min-width: 20%;
  width: auto;
  border: 2px solid var(--primary);
  border-left: none;
  position: relative;
  flex: 1;
}

.half-windows.log_inline .frame-with-legend iframe {
  width: 100%;
  height: 25rem;
}

.half-windows.log_in_new_line .frame-with-legend iframe {
  width: 100%;
  height: 25rem;
}

.half-windows.log_in_new_line .v-codemirror .cm-editor {
  width: 100% !important;
  height: 25rem;
  overflow: auto;
}

.half-windows.log_in_new_line .frame-with-legend {
  border: 2px solid var(--primary);
  border-top: 0;
  position: relative;
}

.challenge-view-with-sidebar {
  display: flex;
}

.challenge-view-with-sidebar .left-sidebar,
.challenge-view-with-sidebar .right-sidebar {
  width: 17%;
  padding: 1rem;
}

.challenge-view-with-sidebar .left-sidebar {
  padding-left: 0;
}

.challenge-view-with-sidebar .right-sidebar {
  padding-right: 0;
}

.challenge-view-with-sidebar .challenge {
  margin: 0 auto;
  position: relative;
  width: 66%;
}
</style>
