module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'findPairs';
    const params = {
      0: [
        'array',
        [1, 2, 3, 4, 5],
        [3, 7, 8, 4, 5, 9],
        [2, 4, 6, 8],
        [1, 3, 5, 7, 9],
        [1, 2, 3, 4],
      ],
      1: ['integer', 5, 12, 10, 13, 8],
    };
    const correct = [
      [
        [1, 4],
        [2, 3],
      ],
      [
        [3, 9],
        [4, 8],
        [5, 7],
      ],
      [
        [2, 8],
        [4, 6],
      ],
      [],
      [],
    ];
    const expectedType = ['array-of-arrays'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
