<template>
  <div id="challenge-list-container" class="challenge-list-container">
    <div
      v-for="(difficulty, index) of allChallenges"
      :key="index"
      class="challenge-box-container"
    >
      <div :class="['top-headline-bar', `${getColor(difficulty.difficulty)}`]">
        <h3>
          {{ getDifficultyName(difficulty.difficulty) }}
          <span class="users-progress">({{ getSolved(difficulty.difficulty) }}/
            {{ getTotal(difficulty.difficulty) }})
          </span>
        </h3>
      </div>
      <div class="challenges">
        <div
          v-for="(challenges, key, difficultyIndex) in difficulty.categories"
          :key="difficultyIndex"
          class="category"
        >
          <p class="category-headline">
            <b>{{ getCategoryName(key) }}</b>
          </p>
          <p
            v-for="(challenge, index) in challenges"
            :key="index"
            class="challenge"
            :class="{ done: challenge.success }"
          >
            <span
              class="check"
              :title="challenge.success ? 'solved' : 'unsolved'"
            />
            <router-link
              title="Start challenge"
              :to="{
                name: 'challenge',
                params: { permalink: challenge['permalink'] },
              }"
            >
              {{ challenge['title'] }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';
import UserService from '@/services/UserService.js';
import config from '@/config.js';

export default {
  props: ['allChallenges', 'categories'],
  data() {
    return {
      countings: [],
    };
  },
  async mounted() {
    let challengeCountings = await ChallengeService.getChallengeCountings();

    if (this.$store.getters.isLoggedIn) {
      let solvedChallengesCounting =
        await UserService.getSolvedChallengesCounting();

      // build generic array with counting
      challengeCountings.forEach((counting) => {
        let difficulty = counting.difficulty;
        let solvedObj = solvedChallengesCounting.find(
          (c) => c.difficulty == difficulty
        );
        let solved = solvedObj ? solvedObj.solved : 0;
        let total = challengeCountings.find(
          (c) => c.difficulty == difficulty
        ).total;
        this.countings.push({ difficulty, solved, total });
      });
    } else {
      // build generic array with counting
      let localStorageDifficulties = [];
      Object.keys(localStorage).forEach(function (key) {
        if (key.indexOf('challenge-') > -1) {
          let entry = JSON.parse(localStorage.getItem(key));

          let diffIn = localStorageDifficulties.find(
            (c) => c.difficulty == entry.challenge_difficulty
          );
          if (!diffIn) {
            localStorageDifficulties.push({
              difficulty: parseInt(entry.challenge_difficulty),
              solved: 1,
            });
          } else {
            diffIn.solved++;
          }
        }
      });

      challengeCountings.forEach((counting) => {
        let difficulty = counting.difficulty;

        let solvedObj = localStorageDifficulties.find(
          (c) => c.difficulty == difficulty
        );
        let solved = solvedObj ? solvedObj.solved : 0;
        let total = challengeCountings.find(
          (c) => c.difficulty == difficulty
        ).total;
        this.countings.push({ difficulty, solved, total });
      });
    }

    // remove difficulty 0 (JavaScript fundamentals)
    this.countings.shift();
  },
  methods: {
    getCategoryName(categoryId) {
      return this.categories
        .find((c) => c.id == categoryId)
        .name.replace('JavaScript', '');
    },
    getDifficultyName(difficulty) {
      return config.DIFFICULTIES.find((d) => d.number == difficulty).name;
    },
    getTotal(difficulty) {
      if (!this.countings.length) return '...'; // if not full loaded
      difficulty = parseInt(difficulty) - 1; // to get index instead of normal number
      return this.countings[difficulty].total;
    },
    getSolved(difficulty) {
      if (!this.countings.length) return '...'; // if not full loaded
      difficulty = parseInt(difficulty) - 1; // to get index instead of normal number
      return this.countings[difficulty].solved;
    },
    getColor(difficulty) {
      return (
        'bg-' + config.DIFFICULTIES.find((d) => d.number == difficulty).color
      );
    },
  },
};
</script>
<style scoped>
h3 .users-progress {
  font-size: 50%;
}
</style>
