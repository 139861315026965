module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'palindrome';
    const params = {
      0: ['string', 'racecar', 'eye', 'baseball', 'otto', 'rotators'],
    };
    const correct = [true, true, false, true, false];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
