module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'firstHello';
    const params = {
      0: [
        'string',
        'Hello world!',
        'Whats up?',
        'Hello, how are you?',
        'No.',
        'Hell, yeah!',
      ],
    };
    const correct = [true, false, true, false, false];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
