module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'json2Object';
    const params = {
      0: [
        'json',
        '\'{"title": "JSCodebox", "category": "2"}\'',
        '\'{"headline": "Test", "title": "JSON", "category": "4"}\'',
        '\'{"params": "none", "title": "Foobar"}\'',
        '\'{"title": "", "hidden": "yes"}\'',
        '\'{"title": "0"}\'',
      ],
    };
    const correct = ['JSCodebox', 'JSON', 'Foobar', '', '0'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
