<template>
  <div class="container general-form">
    <h1>I found a bug! 🔥</h1>

    <div v-if="!success">
      <p class="description">
        Thanks for your help! Please describe the bug exactly!
      </p>
      <p>
        <!-- <input
          v-model="email"
          type="text"
          :placeholder="
            'Your email address*' + ($store.getters.isLoggedIn ? 'yes' : 'no')
          "
          :disabled="!$store.getters.isLoggedIn"
        /> -->
        <input
          v-model="email"
          type="text"
          placeholder="Your email address*"
        >
        <textarea
          v-model="description"
          placeholder="Description*"
        />
      </p>

      <div class="form-row">
        <input
          id="privacy"
          v-model="privacyCheckbox"
          type="checkbox"
        >
        <label for="privacy">I have read and understood the
          <router-link to="/privacy">Privacy Policy</router-link> and agree to
          the storage of my data for a specific purpose.*</label>
      </div>
      <div
        class="btn btn-sm"
        @click="submit"
      >
        Submit
      </div>
    </div>

    <div
      v-if="alert"
      class="alert"
      :class="{
        red: alert.color == 'red',
        bold: alert.color == 'bold',
      }"
      v-html="alert.msg"
    />
  </div>
</template>
<script>
import EssentialService from '@/services/EssentialService.js';
export default {
  data() {
    return {
      email: '',
      description: '',
      alert: { msg: '', color: '' },
      success: false,
      privacyCheckbox: false,
    };
  },
  created() {
    // set email if logged in
    if (this.$store.getters.isLoggedIn) {
      this.email = this.$store.state.user.email;
    }
  },
  methods: {
    async submit() {
      // if privacy not checked
      if (!this.privacyCheckbox) {
        this.showAlert('Please agree to the privacy policy.', 'red');
        return false;
      }

      try {
        const response = await EssentialService.reportBug({
          email: this.email,
          description: this.description,
          challenge_id: this.$route.query.challengeID
            ? this.$route.query.challengeID
            : '',
        });
        this.showAlert(response.msg, 'bold');
        this.success = true;
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    showAlert(msg, color) {
      this.alert.msg = msg;
      this.alert.color = color;
    },
  },
};
</script>
<style scoped lang="css">
.alert {
  margin-top: 1rem;
}
.alert.red {
  color: var(--red);
}
.alert.bold {
  font-weight: 600;
  font-size: 130%;
}
</style>
