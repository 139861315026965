module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'getPrice';
    const params = {
      0: [
        'string',
        'Ice ($4.20)',
        'Sandwich ($3.50)',
        'Milk ($0.99)',
        'Potatoe salad ($6.50)',
        'Chips ($1.35)',
      ],
    };
    const correct = ['$4.20', '$3.50', '$0.99', '$6.50', '$1.35'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
