<template>
  <div class="container">
    <Loading :loading="loading" />
    <div
      v-if="!loading"
      class="container ranking-page no-pd"
    >
      <h1>🥇 Ranking of our best JavaScript Developers</h1>
      <div
        v-if="!unauthorized"
        class="rankings"
      >
        <div class="ranking-container by-credits box-container">
          <h2>Most Experience (XP)</h2>
          <p class="own-rank">
            <i>Your rank: {{ ownXPRank }}</i>
          </p>
          <ol>
            <li
              v-for="(user, index) in byXP"
              :key="index"
            >
              <span><span class="rank">#{{ index + 1 }}</span>
                <router-link
                  :title="'Go to ' + user.username + '\'s profile'"
                  :to="'/profile/' + user.username"
                >{{ user.username }}</router-link>
                <span
                  v-if="user.verified"
                  class="verified-user"
                ><img
                  src="@/assets/img/verified.svg"
                  alt="check icon"
                  :title="user.username + ' is a verified user'"
                ></span></span>
              <div class="users-value">
                {{ user.xp }}
              </div>
            </li>
          </ol>
        </div>
        <div class="ranking-container by-comments box-container">
          <h2>Most solved challenges</h2>
          <p class="own-rank">
            <i>Your rank: {{ ownCommentsRank }}</i>
          </p>
          <ol>
            <li
              v-for="(user, index) in byChallenges"
              :key="index"
            >
              <span><span class="rank">#{{ index + 1 }}</span>
                <router-link
                  :title="'Go to ' + user.username + '\'s profile'"
                  :to="'/profile/' + user.username"
                >{{ user.username }}</router-link>
                <span
                  v-if="user.verified"
                  class="verified-user"
                ><img
                  src="@/assets/img/verified.svg"
                  alt="check icon"
                  :title="user.username + ' is a verified user'"
                ></span></span>
              <div class="users-value">
                {{ user.challenges }}
              </div>
            </li>
          </ol>
        </div>
        <div
          v-if="false"
          class="ranking-container by-comments box-container"
        >
          <h2>Most comments</h2>
          <ol>
            <li
              v-for="(user, index) in byComments"
              :key="index"
            >
              <span><span class="rank">#{{ index + 1 }}</span>
                <router-link
                  :title="'Go to ' + user.username + '\'s profile'"
                  :to="'/profile/' + user.username"
                >{{ user.username }}</router-link>
                <span
                  v-if="user.verified"
                  class="verified-user"
                ><img
                  src="@/assets/img/verified.svg"
                  alt="check icon"
                  :title="
                    user.username + ' is a verified user'
                  "
                ></span></span><span v-if="false">
                ({{ user.comments }} Comment{{
                  user.comments != 1 ? 's' : ''
                }})</span>
              <div class="users-value">
                {{ user.comments }}
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <p
      v-if="unauthorized && !loading"
      class="not-logged-in-info"
    >
      You must be <router-link to="/login">
        logged in
      </router-link> to see the
      ranking.
    </p>
  </div>
</template>
<script>
import RankingService from '@/services/RankingService.js';
import Loading from '@/components/Loading.vue';

export default {
  name: 'RankingView',
  components: {
    Loading,
  },
  data() {
    return {
      byXP: {},
      ownXPRank: -1,
      byComments: {},
      ownCommentsRank: -1,
      byChallenges: {},
      loading: true,
      unauthorized: true,
    };
  },
  async mounted() {
    const byXPRes = await RankingService.getXP();
    const byCommentsRes = await RankingService.getComments();
    const byChallengesRes = await RankingService.getChallenges();
    const ownXPRankRes = await RankingService.getXPRank();
    const ownChallengesRankRes = await RankingService.getChallengeRank();

    if (
      byXPRes.status === 200 &&
      byChallengesRes.status === 200 &&
      byCommentsRes.status === 200 &&
      ownXPRankRes.status === 200 &&
      ownChallengesRankRes.status === 200
    ) {
      this.byXP = byXPRes.data;
      this.byComments = byCommentsRes.data;
      this.byChallenges = byChallengesRes.data;
      this.ownXPRank = ownXPRankRes.data.position;
      this.ownCommentsRank = ownChallengesRankRes.data.position;

      this.unauthorized = false;
    }

    this.loading = false;
  },
};
</script>
<style lang="css" scoped>
.rankings {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
}
.rankings .ranking-container {
  width: 50%;
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  width: calc(50% - 2rem);
  margin: 1rem 0 2rem 0;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  padding: 2rem;
  background-color: var(--light-grey);
}
.rankings .ranking-container h2 {
  text-align: center;
}
.rankings .ranking-container ol > li {
  list-style: none;
  font-size: 18px;
  margin: 8px 0;
  padding: 3px;
  display: flex;
  justify-content: space-between;
}
.rankings .ranking-container ol > li:first-child {
  border-left: 5px solid #ffd700;
  padding-left: 4px;
  margin-left: -4px;
}
.rankings .ranking-container ol > li:nth-child(2) {
  border-left: 5px solid #c0c0c0;
  padding-left: 4px;
  margin-left: -4px;
}
.rankings .ranking-container ol > li:nth-child(3) {
  border-left: 5px solid #cd7f32;
  padding-left: 4px;
  margin-left: -4px;
}
.rankings .ranking-container h2 {
  margin: 0;
}
.rankings .ranking-container .own-rank {
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center;
}
.rankings .ranking-container .rank {
  width: 3rem;
  display: inline-block;
}
</style>
