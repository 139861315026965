module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'reverseLetters';
    const params = {
      0: [
        'array',
        ['\'b\'', '\'d\'', '\'x\'', '\'p\'', '\'c\''],
        ['\'a\'', '\'r\'', '\'s\'', '\'t\'', '\'j\'', '\'e\'', '\'e\'', '\'a\'', '\'y\''],
        ['\'a\'', '\'r\''],
        [],
        ['\'f\''],
      ],
    };
    const correct = [
      ['c', 'p', 'x', 'd', 'b'],
      ['y', 'a', 'e', 'e', 'j', 't', 's', 'r', 'a'],
      ['r', 'a'],
      [],
      ['f'],
    ];
    const expectedType = ['array', 'string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
