import axios from 'axios';

const url = '/api/admin/';

export default {
  addChallenge(challenge) {
    return axios
      .post(url + 'addChallenge', challenge)
      .then((response) => response.data);
  },
};
