module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'keyboardError';
    const params = {
      0: [
        'string',
        'foobar',
        'hello there',
        'this is awesome!',
        'nice challenge',
        'you can do this!',
      ],
      1: [
        'string',
        'fiibnr',
        'hgllu thgrg',
        'thjs js kwesome!',
        'nice challenge',
        'yhu cen dh this?',
      ],
    };
    const correct = [['o', 'a'], ['e', 'o'], ['i', 'a'], [], ['o', 'a', '!']];
    const expectedType = ['array', 'string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
