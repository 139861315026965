module.exports = {
  SETTINGS: {
    HAS_TEST_CASES: false,
  },
  solve: function (iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        resolve(
          bodyContent.indexOf('background-color: blue;') > -1 ||
            bodyContent.indexOf('background: blue;') > -1
        );
      }, 0);
    });
  },
  getChallengePrepareCode: function () {
    return '<div class="bg" style="width: 100%; height: 90vh; color: #FFF; text-shadow: 1px 1px 2px rgba(0, 0, 0, 1); display: flex; align-items: center; justify-content: center;">Bluescreen of death!</div>';
  },
};
