<template>
  <div class="container form-page">
    <h1>Add challenge</h1>
    <div class="form-row">
      <label for="title">Title</label>
      <input
        v-model="challenge.title"
        type="text"
      >
    </div>
    <div class="form-row">
      <label for="difficulty">Difficulty</label>
      <select v-model="challenge.difficulty">
        <option value="0">
          0 (Fundamentals)
        </option>
        <option value="1">
          Easy
        </option>
        <option value="2">
          Medium
        </option>
        <option value="3">
          Hard
        </option>
      </select>
    </div>
    <div class="form-row">
      <label for="permalink">Permalink</label>
      <input
        v-model="challenge.permalink"
        type="text"
      >
    </div>
    <div class="form-row">
      <label for="description">Description</label>
      <textarea v-model="challenge.description" />
    </div>
    <div class="form-row">
      <label for="example">Example</label>
      <textarea v-model="challenge.example" />
    </div>
    <div class="form-row">
      <label for="code">Code</label>
      <textarea v-model="challenge.code" />
    </div>
    <div class="form-row">
      <label for="solution">Solution</label>
      <textarea v-model="challenge.solution" />
    </div>
    <div class="form-row">
      <label for="hint">Hint</label>
      <input
        v-model="challenge.hint"
        type="text"
      >
    </div>
    <div class="form-row">
      <label for="category">Category</label>
      <select v-model="challenge.category_id">
        <option value="1">
          String
        </option>
        <option value="2">
          Number
        </option>
        <option value="3">
          Logic
        </option>
        <option value="4">
          Loop
        </option>
        <option value="5">
          Array
        </option>
        <option value="6">
          DOM
        </option>
        <option value="7">
          JSON
        </option>
        <option value="8">
          Object
        </option>
        <option value="9">
          Recursion
        </option>
        <option value="10">
          Fundamentals
        </option>
      </select>
    </div>
    <div class="form-row">
      <label for="resources">Resources</label>
      <textarea
        v-model="challenge.resources"
        placeholder="link|text (one per line)"
      />
    </div>
    <div
      class="btn"
      @click="submit"
    >
      Submit
    </div>
  </div>
</template>
<script>
import AdminService from '@/services/AdminService.js';

export default {
  data() {
    return {
      challenge: {
        title: '',
        permalink: '',
        difficulty: 1,
        description: '',
        example: '',
        code: '',
        solution: '',
        hint: '',
        category_id: 1,
        resources: '',
      },
    };
  },
  created() {
    // only for admin
    if (
      this.$store.state.user.username != 'webdeasy' &&
      this.$store.state.user.username != 'JSCodebox Team'
    ) {
      this.$router.push('/');
    }
  },
  methods: {
    async submit() {
      try {
        const response = await AdminService.addChallenge(this.challenge);
        alert(response.msg);
      } catch (err) {
        alert(err);
      }
    },
  },
};
</script>
