<template>
  <div class="headline-container">
    <div class="left">
      <h1 v-html="randomTitle" />
      <h2
        class="mt-minus"
        v-html="randomCite"
      />
    </div>
    <div class="right">
      <DayStreak :series="dayStreak" />
      <Circle
        :xp="user.xp"
        width="100"
      />
    </div>
  </div>
</template>
<script>
import Circle from '@/components/Circle.vue';
import DayStreak from '@/components/challengeList/DayStreak.vue';

import UserService from '@/services/UserService';

export default {
  components: {
    Circle,
    DayStreak,
  },
  props: [],
  data() {
    return {
      titles: ['Welcome back', 'Ahoi', 'Hello', 'What\'s up'],
      cites: [
        '!false: it\'s funny because it\'s true',
        '<i>Programmer (noun.)</i><br>A machine that turns coffee into code.',
        'I\'m a programmer. What\'s your superpower?',
        'How many programmers does it take to change a light bulb? None. It\'s a hardware problem.',
        'Programming Pro Tip: Code JavaScript underwater, so nobody could see you crying',
        'Real programmers count from 0.',
      ],
      dayStreak: 1,
      user: {},
      randomCite: '',
      randomTitle: '',
    };
  },
  async mounted() {
    this.randomTitle = this.getRandomTitle();
    this.randomCite = this.getRandomCite();

    this.user = await this.$store.getters.getUser;

    this.dayStreak = await UserService.getDayStreak();
  },
  methods: {
    getRandomTitle() {
      let random = Math.floor(Math.random() * this.titles.length);
      return (
        this.titles[random] + ' <i>' + this.$store.state.user.username + '</i>!'
      );
    },
    getRandomCite() {
      let random = Math.floor(Math.random() * this.cites.length);
      return this.cites[random];
    },
  },
};
</script>
<style scoped>
.headline-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.headline-container h1 {
  margin-bottom: 0;
  text-align: center;
}
.headline-container h2 {
  margin-top: 0;
  text-align: center;
  font-size: 20px;
}
.headline-container h2 .author {
  font-size: 14px;
  font-style: italic;
}
.headline-container .left {
  width: 70%;
}
.headline-container .left h1,
.headline-container .left h2 {
  text-align: left;
}

.headline-container .right {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
