<template>
  <div v-if="showSettings">
    <div class="challenge-settings popup">
      <div class="close" @click="closeSettings">
        ✖
      </div>
      <h1>Settings</h1>
      <div class="inner-settings-container">
        <div class="row">
          <label for="general_theme">General Theme</label>
          <select
            id="general_theme"
            v-model="general_theme"
            @change="changeGeneralTheme"
          >
            <option
              v-for="(loopGeneralTheme, index) in general_themes"
              :key="index"
              :value="loopGeneralTheme"
              :selected="loopGeneralTheme == theme"
            >
              {{ loopGeneralTheme }}
            </option>
          </select>
        </div>
        <div class="row">
          <label for="theme">Editor Theme</label>
          <select
            id="theme"
            v-model="theme"
            @change="$emit('changeTheme', theme)"
          >
            <option
              v-for="(loopTheme, index) in editor_themes"
              :key="index"
              :value="loopTheme"
              :selected="loopTheme == theme"
            >
              {{ loopTheme }}
            </option>
          </select>
        </div>
        <div class="row">
          <label for="theme">Editor Layout</label>

          <div class="inner-rows">
            <div
              v-for="chosenLayout in editorLayouts"
              :key="chosenLayout.id"
              class="row-in-row"
            >
              <input
                :id="chosenLayout.id"
                v-model="editorLayout"
                type="radio"
                :value="chosenLayout.id"
                @change="$emit('changeEditorLayout', chosenLayout.id)"
              />
              <label :for="chosenLayout.id">{{ chosenLayout.label }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="settings-overlay" />
  </div>
</template>
<script>
import config from '@/config.js';

export default {
  name: 'ChallengeSettings',
  props: {
    showSettings: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['closeSettings', 'changeTheme', 'changeEditorLayout'],
  data() {
    return {
      theme: '',
      editor_themes: config.EDITOR_THEMES,
      general_theme: '',
      general_themes: ['light', 'dark'],
      editorLayouts: config.EDITOR_LAYOUTS,
      editorLayout: '',
    };
  },
  created() {
    // get general theme
    if (this.$store.getters.getUserOption('general_theme')) {
      this.general_theme = this.$store.getters.getUserOption('general_theme');
    } else {
      this.general_theme = 'light';
    }

    // get editor theme
    let editorThemeFromStore =
      this.$store.getters.getUserOption('editor_theme');
    let editorThemeFromLocalStorage = localStorage.getItem('editor_theme');
    if (
      !this.$store.getters.isLoggedIn &&
      editorThemeFromLocalStorage &&
      this.editor_themes.indexOf(editorThemeFromLocalStorage) > -1
    ) {
      this.theme = editorThemeFromLocalStorage;
    } else if (
      this.$store.getters.isLoggedIn &&
      editorThemeFromStore &&
      this.editor_themes.indexOf(editorThemeFromStore) > -1
    ) {
      this.theme = editorThemeFromStore;
    } else {
      this.theme = config.DEFAULT_EDITOR_THEME;
    }

    // get editor layout
    if (
      !this.$store.getters.isLoggedIn &&
      localStorage.getItem('editor_layout')
    ) {
      this.editorLayout = localStorage.getItem('editor_layout');
    } else if (this.$store.getters.getUserOption('editor_layout')) {
      this.editorLayout = this.$store.getters.getUserOption('editor_layout');
    } else {
      this.editorLayout = config.DEFAULT_EDITOR_LAYOUT;
    }
  },
  methods: {
    closeSettings() {
      this.$emit('closeSettings');
    },
    changeGeneralTheme() {
      this.$store.dispatch('setUserOption', {
        kex: 'general_theme',
        value: this.general_theme,
      });

      // update for not logged in users manually
      if (!this.$store.getters.isLoggedIn) {
        // TODO automatically change...
        alert('Please reload the page!');
      }
    },
  },
};
</script>
<style scoped lang="css">
.challenge-settings {
  width: 30rem;
  height: auto;
}
.challenge-settings .close {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}
.challenge-settings .row {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}
.challenge-settings .row > label {
  width: 30%;
}
.challenge-settings .row select {
  margin: 0;
}
.settings-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 11;
}
</style>
