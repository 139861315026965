module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'calculateAverageGrade';
    const params = {
      0: [
        'array',
        [85, 90, 92, 88, 76],
        [50, 100, 0],
        [100],
        [70.5, 85.25, 90.75],
        [10, 40, 34, 76, 2],
        [70, 65, 80, 75, 88, 92, 78, 85],
        [60, 70, 80, 90, 100],
        [88.5, 92.3, 76.8, 89.1, 95.7],
      ],
    };
    const correct = [86.2, 50, 100.0, 82.17, 32.4, 79.13, 80.0, 88.48];
    const expectedType = ['float'];

    return {
      type: 'cases',
      payload: {
        functionName,
        params,
        correct,
        expectedType,
        output: true,
      },
    };
  },
};
