module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'getReducedPrice';
    const params = {
      0: ['integer', '1000', '99', '42', '499.95', '3.99'],
      1: ['integer', '20', '10', '3', '15', '80'],
    };
    const correct = [800, 89.1, 40.74, 424.96, 0.8];
    const expectedType = ['float'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
