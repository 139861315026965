module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'getURLParams';
    const params = {
      0: [
        'string',
        'https://jscodebox.com/test.xml?id=3&value=file',
        'https://jscodebox.com/',
        'https://jscodebox.com/index.php?kex=Jfs873nj&id=1&action=delete',
        'https://jscodebox.com/delete.php?api_key=njkASF5',
        'https://jscodebox.com/?action=new&user_id=3&force=false',
      ],
    };
    const correct = [
      [3, 'file'],
      [],
      ['Jfs873nj', 1, 'delete'],
      ['njkASF5'],
      ['new', 3, false],
    ];
    const expectedType = ['array', 'string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
