<template>
  <div class="container challengelist">
    <Loading :loading="loading" />

    <ProfileNotification v-if="$store.state.user.username && !loading" />

    <CategoryFinished
      v-if="categoryFinished && !loading"
      :difficulty="categoryFinished"
    />

    <StartItems v-if="$store.getters.isLoggedIn && !loading" />

    <FirstVisitScreen v-if="!loading && !$store.getters.isLoggedIn" />

    <CategoryChanger
      v-if="$store.getters.isLoggedIn && !loading"
      :categories="categories"
    />

    <IntroChallengeSelection
      v-if="!loading && !$store.getters.isLoggedIn"
      :categories="categories"
      :challenges="challenges"
    />

    <Difficulties
      v-if="!loading"
      :categories="categories"
      :all-challenges="challenges"
    />
    <!-- Ezoic - bottom_of_page - bottom_of_page -->
    <div id="ezoic-pub-ad-placeholder-102" />
    <!-- End Ezoic - bottom_of_page - bottom_of_page -->
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';

import StartItems from '@/components/challengeList/StartItems.vue';
import Loading from '@/components/Loading.vue';
import CategoryFinished from '@/components/challengeList/CategoryFinished.vue';
import CategoryChanger from '@/components/challengeList/CategoryChanger.vue';
import Difficulties from '@/components/challengeList/Difficulties.vue';
import ProfileNotification from '@/components/challengeList/ProfileNotification.vue';
import IntroChallengeSelection from '@/components/challengeList/IntroChallengeSelection.vue';
import FirstVisitScreen from '@/components/challengeList/FirstVisitScreen.vue';

export default {
  name: 'ChallengeList',
  components: {
    StartItems,
    FirstVisitScreen,
    Loading,
    CategoryFinished,
    CategoryChanger,
    Difficulties,
    ProfileNotification,
    IntroChallengeSelection,
  },
  // props: ['categoryFinished'],
  data() {
    return {
      randomChallengePermalink: 'hello-world',
      categories: [],
      easyChallenges: [],
      challenges: {},
      loading: true,
      firstVisit: true,
      categoryFinished: false,
    };
  },
  async created() {
    this.categoryFinished = this.$route.query.categoryFinished;
    const unsortedChallenges = await ChallengeService.getChallenges();
    let easyChallenges = []; // add all easy challengeIDs to get a random number later

    unsortedChallenges.forEach((challenge) => {
      // add success state
      // if not logged in and in localStorage
      if (
        !this.$store.getters.isLoggedIn &&
        localStorage.getItem('challenge-' + challenge.id)
      ) {
        challenge.success = JSON.parse(
          localStorage.getItem('challenge-' + challenge.id)
        ).done;
      } else if (this.$store.getters.getUserChallenge(challenge.id)) {
        challenge.success = this.$store.getters.getUserChallenge(
          challenge.id
        ).done;
      }

      // get difficulty
      let difficulty = challenge.difficulty;

      // get category
      let categoryId = challenge.category_id;

      // add difficulty
      if (!this.challenges[difficulty]) {
        this.challenges[difficulty] = {
          difficulty,
          categories: {},
        };
      }

      if (parseInt(difficulty) === 1) easyChallenges.push(challenge.permalink);

      // add category
      if (!this.challenges[difficulty]['categories'][categoryId]) {
        this.challenges[difficulty]['categories'][categoryId] = [];

        if (
          !this.categories.find(
            (cObject) => cObject.id == challenge.category_id
          )
        ) {
          this.categories.push({
            id: challenge.category_id,
            name: challenge.category_name,
            categoryPermalink: challenge.category_permalink,
          });
        }
      }

      // set challenge in specific category
      this.challenges[difficulty]['categories'][categoryId].push(challenge);
    });

    // get random challenge from easy category
    let randIndex = Math.floor(Math.random() * easyChallenges.length);
    this.randomChallengePermalink = easyChallenges[randIndex];

    this.loading = false;
  },
};
</script>
<style scoped>
.category-headline {
  font-size: 1em;
}
</style>
