module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'minimumCost';
    const params = {
      0: [
        'array-of-arrays',
        [
          [1, 2],
          [3, 4],
        ],
        [
          [4, 2, 1],
          [1, 1, 1],
          [3, 2, 4],
        ],
        [
          [1, 2, 3],
          [4, 5, 6],
          [7, 8, 9],
        ],
        [
          [1, 3, 5, 7],
          [2, 4, 6, 8],
          [0, 9, 7, 5],
          [1, 7, 9, 3],
        ],
        [
          [1, 10, 5, 3],
          [2, 6, 1, 5],
          [9, 2, 8, 1],
          [1, 3, 1, 6],
        ],
      ],
    };
    const correct = [7, 11, 21, 23, 21];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
