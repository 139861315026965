<template>
  <div class="comments-container">
    <Loading :loading="loading" />
    <div
      v-if="!loading"
      class="comments-area"
    >
      <p v-if="!comments.length">
        There are no comments for this challenge yet, be the first!
      </p>
      <div
        v-for="cmt in comments"
        :key="cmt.id"
        class="comment"
      >
        <p class="comment-infos">
          <b><router-link
            target="_blank"
            :title="'Go to ' + cmt.username + '\'s profile'"
            :to="'/profile/' + cmt.username"
          >{{ cmt.username }}</router-link></b><span
            v-if="cmt.verified"
            class="verified-user"
          ><img
            src="@/assets/img/verified.svg"
            alt="check icon"
            :title="cmt.username + ' is a verified user'"
          /></span>
          <span
            class="date"
            :title="cmt.date"
          >
            ({{ $options.filters.removeTimeFromDate(cmt.date) }})</span>
        </p>
        <div
          class="comment-content"
          v-html="$options.filters.addFormatting(cmt.comment)"
        ></div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="add-comment-area"
    >
      <div v-if="$store.getters.isLoggedIn">
        <textarea
          v-if="!success"
          v-model="comment"
          type="text"
          placeholder="Please be nice and respectful. Only give hints, not complete solutions!"
        />
        <div class="inline-block">
          <p
            v-if="!success"
            class="btn btn-sm"
            @click="submitComment"
          >
            Submit
          </p>
          <!--  -->
          <span
            class="small-txt more-margin"
            href="#"
          >Use
            <a
              href="https://dev.to/madhavgupta/a-comprehensive-guide-on-markdown-3o2n"
              target="_blank"
            >Markdown</a>
            to format your comment.<br /><b>Example:</b> Use `console.log('Hi')`
            to get this result <code>console.log('Hi')</code></span>
        </div>
        <div
          v-if="alert"
          class="alert"
          :class="{
            red: alert.color == 'red',
            green: alert.color == 'green',
          }"
          v-html="alert.msg"
        />
      </div>
      <p
        v-if="!$store.getters.isLoggedIn"
        class="not-logged-in-info"
      >
        You must be <router-link to="/login">
          logged in
        </router-link> to post a
        comment.
      </p>
    </div>
    <!-- Ezoic - under_comments - longer_content -->
    <div id="ezoic-pub-ad-placeholder-106" />
    <!-- End Ezoic - under_comments - longer_content -->
  </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import ChallengeService from '@/services/ChallengeService.js';

// Load JS and CSS for Markdown Styling
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);
import '@/assets/highlight.css';

export default {
  name: 'CommentsComponent',
  components: {
    Loading,
  },
  filters: {
    /**
     * Adds formatting to a passed comment
     */
    addFormatting(value) {
      // init MarkdownIt if not already done
      if (!this.md) {
        this.setupMarkdown();
      }

      // replace - to html entity
      value = value.replace(/-/g, '&#8209;');

      // make markdown
      value = this.md.render(value);

      // highlight name mentions and link them to their profile, e.g. @webdeasy
      value = value.replace(
        /@([a-zA-Z0-9_-]{3,})/g,
        '<a class="auto-linked-profile" href="/profile/$1" target="_blank">@$1</a>'
      );

      return value;
    },
    /**
     * Removes the time from a date.
     * 01.01.1970 08:00 => 01.01.1970
     */
    removeTimeFromDate(date) {
      return date.split(' ')[0];
    },
    /**
     * Setup the MarkdownIt instance
     */
    setupMarkdown() {
      this.md = new MarkdownIt({
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(str, { language: lang }).value;
            } catch (__) {}
          }
          return '';
        },
        breaks: true,
        linkify: true,
        false: true,
      });
      this.md.disable(['heading']);
    },
  },
  props: {
    challengeID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comments: {},
      comment: '',
      alert: { msg: '', color: '' },
      success: false,
      showFormatting: false,
      loading: true,
      md: undefined, // MarkdownIt instance
    };
  },
  async created() {
    // load comments
    this.comments = await ChallengeService.getComments(this.challengeID);
    this.loading = false;
  },
  methods: {
    submitComment: async function () {
      try {
        const response = await ChallengeService.addComment({
          challengeID: this.challengeID,
          comment: this.comment,
        });

        this.showAlert(response.msg, 'green');

        // current date in dd.mm.YYYY format
        const formattedDate = new Date()
          .toLocaleDateString()
          .split('/')
          .reverse()
          .join('.');

        this.comments.unshift({
          username: this.$store.state.user.username,
          comment: this.comment,
          date: formattedDate,
        });

        this.success = true;
        this.comment = '';

        // update user var
        this.$store.dispatch('getUser');
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    showAlert: function (msg, color = '') {
      this.alert.msg = msg;
      this.alert.color = color;
    },
    removeAlert: function () {
      this.alert = [];
    },
  },
};
</script>
<style lang="css">
.comment pre {
  font-family: monospace;
  background: var(--light-grey);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 5px;
  font-size: 16px;
  white-space: pre-wrap;
}

.comment pre .language-javascript,
.comment pre .language-javascript span {
  font-size: 16px !important;
}

.comments-container {
  border: 2px solid var(--primary);
  padding: 1rem;
  box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}

.comments-area {
  max-height: 25rem;
  overflow-y: scroll;
}

.comments-area .comment {
  padding: 1rem 0 1rem 0.5rem;
  margin: 0.2rem 0;
  border-bottom: 1px solid var(--grey);
}

.comments-area .comment p {
  margin: 0;
}

.comments-area .comment .comment-infos {
  margin-bottom: 0.5rem;
}

.comment span {
  white-space: pre-wrap;
}

.comment .auto-linked-profile {
  font-style: italic;
}

.comment .date {
  font-size: 60%;
  font-style: italic;
  opacity: 0.5;
}

.comment .comment-content p,
.comment .comment-content a {
  font-size: 16px;
}

.comment .comment-content img {
  max-width: 35%;
}

.add-comment-area {
  padding-top: 1rem;
}

.add-comment-area textarea {
  margin-bottom: 0;
  height: 5rem;
}

.formatting-info-btn {
  margin-left: 1rem;
  font-size: 80%;
}
</style>
