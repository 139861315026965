<template>
  <div>
    <div class="container middle-form-container box-container">
      <div class="inner">
        <h1>Create a <u>free</u> account</h1>
        <input
          v-model="email"
          type="email"
          placeholder="Email address*"
          autoFocus
        >
        <input
          v-model="username"
          type="text"
          placeholder="Username*"
        >
        <input
          v-model="password"
          type="password"
          placeholder="Password*"
        >
        <input
          v-model="password_repeat"
          type="password"
          placeholder="Password (repeat)*"
        >
        <div class="form-row">
          <input
            id="privacy"
            v-model="privacyCheckbox"
            type="checkbox"
          >
          <label for="privacy">I have read and understood the
            <router-link to="/privacy">Privacy Policy</router-link> and agree to
            the storage of my data for a specific purpose.*</label>
        </div>

        <div
          class="btn btn-sm"
          @click="submit"
        >
          Sign up
        </div>

        <p class="info">
          You already have an account?
          <router-link to="/login">
            Login here
          </router-link>!
        </p>

        <div
          v-if="alert"
          class="alert"
          :class="{
            red: alert.color == 'red',
            green: alert.color == 'green',
          }"
          v-html="alert.msg"
        />
      </div>
    </div>
    <!-- Ezoic - under_modal_windows - under_page_title -->
    <div id="ezoic-pub-ad-placeholder-111" />
    <!-- End Ezoic - under_modal_windows - under_page_title -->
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';
import { useToast } from 'vue-toastification';

export default {
  name: 'SignUp',
  data() {
    return {
      email: '',
      username: '',
      password: '',
      privacyCheckbox: false,
      password_repeat: '',
      alert: { msg: '', color: '' },
      toast: useToast(),
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) this.$router.push('/');
  },
  methods: {
    async submit() {
      this.showAlert('We\'ll prepare everything for you...');
      try {
        const response = await UserService.register({
          email: this.email,
          privacy: this.privacyCheckbox,
          username: this.username,
          password: this.password,
          password_repeat: this.password_repeat,
        });
        this.showAlert(response.msg, 'green');

        // login user
        const loginResponse = await UserService.login({
          loginKey: this.username,
          password: this.password,
        });
        this.showAlert(loginResponse.msg, 'green');

        this.$store.dispatch('login', {
          token: loginResponse.token,
          user: loginResponse.user,
        });
        this.$router.push('/');
        this.toast.success(
          'Your registration was successful. Have fun coding!'
        );

        this.saveChallengesFromLocalStorage();
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    showAlert: function (msg, color = '') {
      this.alert.msg = msg;
      this.alert.color = color;
    },
    saveChallengesFromLocalStorage() {
      // TODO
    },
  },
};
</script>
