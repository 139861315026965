<template>
  <div class="container">
    <h1>Privacy</h1>

    <h2>
      Allgemeiner Hinweis und PflichtinformationenBenennung der verantwortlichen
      Stelle
    </h2>

    <p>
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
      ist:<br>
    </p>

    <p>
      <b>Dev LH</b><br>Lorenz Hohmann<br>Am Birkicht 5<br>36145 Hofbieber
    </p>

    <p>
      Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
      über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
      (z.B. Namen, Kontaktdaten o. Ä.).
    </p>

    <h2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>

    <p>
      Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
      Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
      Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose
      Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
      Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h2>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>

    <p>
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
      Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
      der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
      unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
      Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        target="_blank"
        rel="noreferrer noopener"
      >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
    </p>

    <h2>Recht auf Datenübertragbarkeit</h2>

    <p>
      Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung
      oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
      an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
      maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an
      einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
      technisch machbar ist.
    </p>

    <h2>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h2>

    <p>
      Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck
      der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit über die im Impressum
      aufgeführten Kontaktmöglichkeiten an uns wenden.
    </p>

    <h2>SSL- bzw. TLS-Verschlüsselung</h2>

    <p>
      Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
      Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
      eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
      Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
      verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und
      am Schloss-Symbol in der Browserzeile.
    </p>

    <h2>Server-Log-Dateien</h2>

    <p>
      In Server-Log-Dateien erhebt und speichert der Provider der Website
      automatisch Informationen, die Ihr Browser automatisch an uns übermittelt.
      Dies sind:
    </p>

    <ul>
      <li>Besuchte Seite auf unserer Domain</li>
      <li>Datum und Uhrzeit der Serveranfrage</li>
      <li>Browsertyp und Browserversion</li>
      <li>Verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>IP-Adresse</li>
    </ul>

    <p>
      Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
      statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO,
      der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </p>

    <h2>Kontaktformular</h2>

    <p>
      Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
      Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für
      Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne
      Ihre Einwilligung nicht statt.
    </p>

    <p>
      Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
      ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
      DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
      möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
      Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge
      bleibt vom Widerruf unberührt.
    </p>

    <p>
      Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie
      uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen
      oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende
      gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
      unberührt.
    </p>

    <h2>Speicherdauer von Beiträgen und Kommentaren</h2>

    <p>
      Beiträge und Kommentare sowie damit in Verbindung stehende Daten, wie
      beispielsweise IP-Adressen, werden gespeichert. Der Inhalt verbleibt auf
      unserer Website, bis er vollständig gelöscht wurde oder aus rechtlichen
      Gründen gelöscht werden musste.
    </p>

    <p>
      Die Speicherung der Beiträge und Kommentare erfolgt auf Grundlage Ihrer
      Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits
      erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
      formlose Mitteilung per E-Mail. Die Rechtmäßigkeit bereits erfolgter
      Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
    </p>

    <h2>Cookies</h2>

    <p>
      Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr
      Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser
      Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
    </p>

    <p>
      Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende
      Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies
      auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies
      helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
    </p>

    <p>
      Mit einem modernen Webbrowser können Sie das Setzen von Cookies
      überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so
      konfigurieren, dass Cookies mit dem Schließen des Programms von selbst
      gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte
      Funktionalität unserer Website zur Folge haben.
    </p>

    <p>
      Das Setzen von Cookies, die zur Ausübung elektronischer
      Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
      erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
      Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
      haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur
      technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
      Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt,
      werden diese in dieser Datenschutzerklärung separat behandelt.
    </p>

    <h2>Google AdSense</h2>
    <p>
      Diese Website verwendet Google AdSense. Es handelt sich hierbei um einen
      Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043, USA, zur Einbindung von Werbeanzeigen. Google AdSense verwendet
      Cookies. Dies sind Dateien, durch deren Speicherung auf Ihrem PC Google
      die Daten Ihrer Benutzung unserer Website analysieren kann.
    </p>

    <p>
      Zudem werden bei Google AdSense zusätzlich Web Beacons verwendet, nicht
      sichtbare Grafiken, die es Google ermöglichen, Klicks auf dieser Website,
      den Verkehr auf dieser und ähnliche Informationen zu analysieren. Die über
      Cookies und Web Beacons erhaltenen Informationen, Ihre IP-Adresse sowie
      die Auslieferung von Werbeformaten werden an einen Server von Google mit
      Standort in den USA übermittelt und dort gespeichert. Google wird diese
      gesammelten Informationen möglicherweise an Dritte weitergeben, wenn dies
      gesetzlich erforderlich ist oder Google gegenüber Dritten die
      Datenverarbeitung in Auftrag gibt. Allerdings wird Google Ihre IP-Adresse
      zusammen mit den anderen gespeicherten Daten zusammenführen.
    </p>

    <p>
      Durch entsprechende Einstellungen an Ihrem Internetbrowser können Sie
      verhindern, dass die genannten Cookies auf Ihrem PC gespeichert werden.
      Dadurch besteht jedoch die Möglichkeit, dass die Inhalte dieser Website
      nicht mehr in gleichem Umfang genutzt werden können. Durch die Nutzung
      dieser Website willigen Sie in die Bearbeitung der zu Ihrer Person
      erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und
      zu dem zuvor benannten Zweck ein.
    </p>

    <h2>Matomo</h2>

    <p>
      Auf dieser Website werden unter Einsatz der Webanalysedienst-Software
      Matomo (<a
        rel="noreferrer noopener"
        aria-label="www.matomo.org (öffnet in neuem Tab)"
        href="https://www.matomo.org"
        target="_blank"
      >www.matomo.org</a>), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011
      Wellington, Neuseeland, („Matomo“) auf Basis unseres berechtigten
      Interesses an der statistischen Analyse des Nutzerverhaltens zu
      Optimierungs- und Marketingzwecken gemäß Art. 6 Abs. 1 lit. f DSGVO Daten
      gesammelt und gespeichert. Aus diesen Daten können zum selben Zweck
      pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Hierzu
      können Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine
      Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des
      Seitenbesuchers gespeichert werden. Die Cookies ermöglichen unter anderem
      die Wiedererkennung des Internet-Browsers. Die mit der Matomo-Technologie
      erhobenen Daten (einschließlich Ihrer pseudonymisierten IP-Adresse) werden
      auf unseren Servern verarbeitet. .<br>Die durch das Cookie erzeugten
      Informationen im pseudonymen Nutzerprofil werden nicht dazu benutzt, den
      Besucher dieser Website persönlich zu identifizieren und nicht mit
      personenbezogenen Daten über den Träger des Pseudonyms
      zusammengeführt.&nbsp;
    </p>

    <p>
      Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch
      nicht einverstanden sind, dann können Sie der Speicherung und Nutzung
      nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in
      Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass
      Matomo keinerlei Sitzungsdaten erhebt. Bitte beachten Sie, dass die
      vollständige Löschung Ihrer Cookies zur Folge hat, dass auch das
      Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden
      muss.
    </p>

    <iframe
      style="border: 0; height: 120px; width: 100%"
      src="https://analytics.jscodebox.com/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=a8a8a8&fontColor=ffffff&fontSize=1em&fontFamily=sans-serif"
    />

    <p>
      Quelle: Datenschutz-Konfigurator von
      <a
        href="http://www.mein-datenschutzbeauftragter.de"
        target="_blank"
        rel="noreferrer noopener"
        aria-label=" (öffnet in neuem Tab)"
      >mein-datenschutzbeauftragter.de</a>
    </p>
  </div>
</template>
<script>
export default {
  methods: {
    disableTracking: function () {
      this.$ga.disable();
      alert('Tracking disabled');
    },
  },
};
</script>
