module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'either404';
    const params = {
      0: [
        'array',
        [4, 3, 1],
        [2, 8, 4, 4],
        [0, 0, 3, 6, 4, 4],
        [3, 4, 2, 4, 6],
        [1, 4, 4, 0, 2, 0, 9],
      ],
    };
    const correct = [false, true, false, false, true];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
