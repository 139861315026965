<template>
  <div class="streak-wrapper">
    <div
      class="label"
      title="The series of days you are logged in without skipping a day"
    >
      <span class="small">DayStreak</span>{{ series }}<br>
      <img
        class="icon"
        src="@/assets/img/timeline.svg"
        alt="timeline"
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ['series'],
};
</script>
<style lang="css" scoped>
.streak-wrapper {
  position: relative;
  margin-right: 4rem;
}
.streak-wrapper .label {
  text-align: center;
  line-height: 20px;
  color: var(--grey);
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 25px;
  display: flex;
  flex-direction: column;
}
.streak-wrapper .small {
  font-size: 60%;
}

.streak-wrapper .icon {
  width: 4rem;
  position: absolute;
  top: -65px;
  left: 3px;
}
</style>
