import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMatomo from 'vue-matomo';
import Axios from 'axios';
import VueConfetti from 'vue-confetti';

const app = createApp(App, {
  router,
  store,
});

app.use(VueConfetti);
app.use(store);
app.use(router);

app.use(VueMatomo, {
  host: 'https://analytics.jscodebox.com',
  siteId: 1,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  trackerFileName: 'piwik',
  debug: true,
});

import Toast, { POSITION } from 'vue-toastification';

import 'vue-toastification/dist/index.css';
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});

import VueSmoothScroll from 'v-smooth-scroll';
app.use(VueSmoothScroll, {
  duration: 1000,
  updateHistory: false,
});

app.config.productionTip = false;

import '@/assets/styles.css';
import '@/assets/responsive.css';

// set auth header
Axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${store.getters.getToken}`;

router.afterEach(() => {
  // update ezoic ads
  window.ezstandalone = window.ezstandalone || {};
  ezstandalone.cmd = ezstandalone.cmd || [];
  ezstandalone.cmd.push(function () {
    ezstandalone.define(
      102,
      103,
      104,
      105,
      // 106,
      107,
      109,
      110,
      // 111,
      112,
      114,
      115,
      119
    );
    ezstandalone.refresh();
  });
});

// router actions
router.beforeEach((to) => {
  // scroll to top
  window.scrollTo(0, 0);

  // change page title
  document.title = to.meta.title;
  // next();

  // check login
  if (store.getters.isLoggedIn) {
    store.dispatch('checkAuth').catch(() => {
      store.dispatch('logout');
      // TODO toast not working
      // const toast = useToast();
      // toast.error('Your session is invalid. Please login again.');
      alert('Your session is invalid. Please login again.');
    });
  }
});

// check login every 30 seconds
setInterval(() => {
  // check login from server
  if (store.getters.isLoggedIn) {
    store.dispatch('checkAuth').catch(() => {
      store.dispatch('logout');
    });
  }
}, 30000);

// check adblocker on first visit
fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js')
  .then(() => {
    window._paq.push(['trackEvent', 'Adblocker', 'First Visit', 'Disabled']);
  })
  .catch(() => {
    window._paq.push(['trackEvent', 'Adblocker', 'First Visit', 'Enabled']);
  });

app.mount('#app');
