module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'fizzBuzz';
    const params = {
      0: ['integer', 1, 3, 3, 4, 49],
      1: ['integer', 5, 9, 16, 13, 53],
    };
    const correct = [
      ['1', '2', 'Fizz', '4', 'Buzz'],
      ['Fizz', '4', 'Buzz', 'Fizz', '7', '8', 'Fizz'],
      [
        'Fizz',
        '4',
        'Buzz',
        'Fizz',
        '7',
        '8',
        'Fizz',
        'Buzz',
        '11',
        'Fizz',
        '13',
        '14',
        'FizzBuzz',
        '16',
      ],
      ['4', 'Buzz', 'Fizz', '7', '8', 'Fizz', 'Buzz', '11', 'Fizz', '13'],
      ['49', 'Buzz', 'Fizz', '52', '53'],
    ];
    const expectedType = ['array', 'string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
