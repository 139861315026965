module.exports = {
  solve: function (iframeDoc, jsCode, bc) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        resolve(
          (bc.indexOf('<p style="color: green;">Hi there!</p>') > -1 ||
            bc.indexOf('<p style="color: green">Hi there!</p>') > -1 ||
            bc.indexOf('<p style="color:green;">Hi there!</p>') > -1 ||
            bc.indexOf('<p style="color:green">Hi there!</p>') > -1) &&
            (bc.indexOf('<p style="color: blue;">Hello there!</p>') > -1 ||
              bc.indexOf('<p style="color: blue">Hello there!</p>') > -1 ||
              bc.indexOf('<p style="color:blue;">Hello there!</p>') > -1 ||
              bc.indexOf('<p style="color:blue">Hello there!</p>') > -1) &&
            (bc.indexOf('<p style="color: red;">Hello, Hi!</p>') > -1 ||
              bc.indexOf('<p style="color: red">Hello, Hi!</p>') > -1 ||
              bc.indexOf('<p style="color:red;">Hello, Hi!</p>') > -1 ||
              bc.indexOf('<p style="color:red">Hello, Hi!</p>') > -1)
        );
      }, 0);
    });
  },
  getTestCases: function () {
    const functionName = 'headline2';
    const params = {
      0: [
        'string',
        'Hi there!',
        'Hello there!',
        'Whats up?',
        'Hello, Hi!',
        'LoL',
      ],
    };
    const correct = [];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: false },
    };
  },
};
