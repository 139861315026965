module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'countMe';
    const params = {
      0: [
        'string',
        'Meishere',
        'thisisxMe',
        'MeisxMe',
        'xMeisxMe',
        'MeixsyouMe',
      ],
    };
    const correct = [1, 0, 1, 0, 1];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
