module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'timeConvert';
    const params = {
      0: ['integer', 1000, 59, 61, 1440, 0, 34303],
    };
    const correct = ['16:40', '00:59', '01:01', '24:00', '00:00', '571:43'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
