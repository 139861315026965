module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'object2JSON';
    const params = {
      0: [
        'object',
        { name: 'JSCodebox', release: 'yesterday' },
        {},
        { number: 1, title: 'Foobar' },
        { headline: 'New Headline', date_format: 'dd.mm.yyyy' },
        { name: 'JavaScript Challenge' },
      ],
    };
    const correct = [
      '{"name":"JSCodebox","release":"yesterday"}',
      '{}',
      '{"number":1,"title":"Foobar"}',
      '{"headline":"New Headline","date_format":"dd.mm.yyyy"}',
      '{"name":"JavaScript Challenge"}',
    ];
    const expectedType = ['json'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
