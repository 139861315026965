<template>
  <div class="container">
    <h1>404 - Oops!</h1>
    <h2>I don't think you're right here..!</h2>
    <Mascot />
  </div>
</template>
<script>
import Mascot from '@/components/Mascot.vue';

export default {
  components: {
    Mascot,
  },
};
</script>
<style scoped lang="css">
h1 {
  font-size: 6em;
}
h2 {
  font-size: 2.5em;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
