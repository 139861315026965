module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'swap';
    const params = {
      0: [
        'array',
        [1, 5, 3, 7, 2, 7, 3],
        [1, 6, 2, 7, 9, 3, 4],
        [1, 0],
        [1],
        [],
      ],
    };
    const correct = ['3,5,3,7,2,7,1', '4,6,2,7,9,3,1', '0,1', '1', ''];
    const expectedType = ['array', 'integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
