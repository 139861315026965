module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'isQualified';
    const params = {
      0: [
        'array',
        [8, 9, 10, 13, 14, 15, 18, 19],
        [8, 11, 10, 13, 14, 15, 18, 19],
        [9, 10, 8, 14, 15, 13, 19, 20],
        [9, 10, 8, 14, 15, 13, 19, 20],
        [9, 11, 7, 14, 15, 16, 18, 19],
        [10, 10, 10, 15, 15, 15, 20, 20],
        [8, 9, 9, 14, 15, 14, 19, 21],
        [8, 7, 10, 14, 13, 15, 18, 19]
      ],
      1: ['integer', 110, 110, 108, 141, 109, 140, 109, 104],
    };
    const correct = ['qualified', 'disqualified', 'qualified', 'disqualified', 'disqualified', 'qualified', 'disqualified', 'qualified'];
    const expectedType = ['string'];    

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
