<template>
  <div
    v-if="showSolutions && lastChallenges.length"
    class="user-solutions"
  >
    <h2
      class="user-solution-headline"
      :class="{ open: showContent }"
      @click="showContent = showContent"
    >
      Solutions of other JSCodebox Users
    </h2>
    <div
      v-if="showContent && lastChallenges.length"
      class="user-solutions-container"
    >
      <div
        v-for="challenge in lastChallenges"
        :key="challenge.id"
        class="entry"
      >
        <p>
          <b>
            <router-link
              target="_blank"
              :title="'Go to ' + challenge.username + '\'s profile'"
              :to="'/profile/' + challenge.username"
            >{{ challenge.username }}</router-link>
            <span
              v-if="challenge.user_verified"
              class="verified-user"
            ><img
              src="@/assets/img/verified.svg"
              alt="check icon"
              :title="challenge.username + ' is a verified user'"
            ></span>
          </b>
          <span
            v-if="false"
            class="small"
          > ({{ challenge.last_change }})</span>
        </p>
        <codemirror
          v-model="challenge.code"
          :extensions="cmExtensions"
          :disabled="true"
          :tab-size="2"
          :style="{}"
        />
      </div>
      <p v-if="!lastChallenges.length">
        There are no entries yet. 😔 <b>Be the first!</b><br>
        <span v-if="!$store.getters.isLoggedIn">All you have to do is to
          <router-link to="/login">login</router-link> and continue programming.
        </span>
        <span v-if="$store.getters.isLoggedIn">You just have to program a valid solution.</span>
      </p>
    </div>
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';
import config from '@/config.js';

import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';

// import editor styles
import { oneDark } from '@codemirror/theme-one-dark';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { tokyoNightDay } from '@uiw/codemirror-theme-tokyo-night-day';
import { tokyoNight } from '@uiw/codemirror-theme-tokyo-night';
import { atomone } from '@uiw/codemirror-theme-atomone';
import { aura } from '@uiw/codemirror-theme-aura';
import { nord } from '@uiw/codemirror-theme-nord';
import { okaidia } from '@uiw/codemirror-theme-okaidia';

export default {
  name: 'UserSolutions',
  components: {
    Codemirror,
  },
  props: ['challengeID', 'showSolutions'],
  data() {
    return {
      showContent: true,
      lastChallenges: {},
      cmExtensions: [javascript(), okaidia],
      editorThemes: config.EDITOR_THEMES,
      cmThemes: {
        'one-dark': oneDark,
        eclipse: eclipse,
        sublime: sublime,
        tokyoNightDay: tokyoNightDay,
        tokyoNight: tokyoNight,
        atomone: atomone,
        aura: aura,
        nord: nord,
        okaidia: okaidia,
      },
    };
  },
  watch: {
    showSolutions: async function () {
      try {
        this.lastChallenges = await ChallengeService.getLastChallenges(
          this.challengeID
        );
      } catch (err) {
        // console.log(err);
      }
    },
  },
  created() {
    // set theme
    let editorThemeFromStore =
      this.$store.getters.getUserOption('editor_theme');
    if (
      editorThemeFromStore &&
      this.editorThemes.indexOf(editorThemeFromStore) > -1
    ) {
      this.cmExtensions.pop();
      this.cmExtensions.push(this.cmThemes[editorThemeFromStore]);
    } else {
      this.cmExtensions.pop();
      this.cmExtensions.push(this.cmThemes[config.DEFAULT_EDITOR_THEME]);
    }
  },
  methods: {},
};
</script>
<style scoped lang="css">
.entry {
  margin-bottom: 2rem;
  width: calc(50% - 2rem);
}
.entry:last-child {
  margin-bottom: 0;
}
.user-solution-headline {
  margin-top: 2rem;
  position: relative;
}
.user-solutions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
