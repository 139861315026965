<template>
  <footer>
    <div class="links">
      <div class="container">
        <a
          href="https://webdeasy.de/en/category/javascript-en/"
          class="bold"
          target="_blank"
          title="JavaScript Tutorials"
        >JavaScript Tutorials</a>
        <router-link
          class="bold"
          to="/bug-report"
          title="Bug Report"
        >
          Bug Report
        </router-link>
        <router-link
          class="bold"
          to="/submit-challenge"
          title="Submit a challenge"
        >
          Submit a challenge
        </router-link>
      </div>
      <div class="container">
        <router-link
          to="/imprint"
          rel="nofollow noindex"
          title="Imprint"
        >
          Imprint
        </router-link>
        <router-link
          to="/privacy"
          rel="nofollow noindex"
          title="Privacy"
        >
          Privacy
        </router-link>
        <a
          href="https://twitter.com/JSCodebox"
          target="_blank"
          title="Twitter"
        >Twitter</a>
      </div>
    </div>
  </footer>
</template>
<style scoped>
footer {
  margin-top: 5rem;
}
footer .container {
  display: flex;
  padding: 0;
  justify-content: center;
}
footer .container:first-child {
  padding-top: 0.5rem;
}
footer .container:last-child {
  padding-bottom: 0.5rem;
}
footer .container .bold {
  font-weight: 600;
}
footer .container a {
  margin: 0.3rem 1rem;
  text-align: center;
}
.Cookie > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}
.Cookie > div span {
  background-color: var(--primary);
  color: var(--white);
  padding: 1rem 2rem;
  opacity: 0.8;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  cursor: pointer;
  width: 120px;
  text-align: center;
  display: block;
  font-size: 1em;
}
.Cookie > div span:hover {
  opacity: 1;
}
</style>
