module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'more4than0';
    const params = {
      0: ['array', [1, 4, 0], [3, 1, 4, 4, 4, 1], [4, 0, 4, 0], [], [4]],
    };
    const correct = [false, true, false, false, true];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
