<template>
  <div>
    <UserSolutions
      :challenge-i-d="challengeID"
      :show-solutions="showSolutions"
    />
  </div>
</template>
<script>
import UserSolutions from '@/components/userarea/UserSolutions.vue';

export default {
  name: 'UserArea',
  components: {
    UserSolutions,
  },
  props: ['challengeID', 'showSolutions'],
};
</script>
<style scoped lang="css"></style>
