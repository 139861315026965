<template>
  <div>
    <div class="form-row">
      <label for="username">Username</label>
      <div class="double-area">
        <input
          id="username"
          v-model="username"
          type="text"
          disabled
        >
        <span class="small-under">You can't change your username.</span>
      </div>
    </div>
    <div class="form-row">
      <p>Verified Account</p>
      <div class="double-area">
        <p>{{ user.verified ? 'Yes' : 'No' }}</p>
        <span class="small-under">Verified accounts have been authenticated through JSCodebox's
          team.</span>
      </div>
    </div>
    <div class="form-row">
      <label for="email">Email address</label>
      <input
        id="email"
        v-model="email"
        type="email"
      >
    </div>
    <div class="form-row">
      <label for="website">Website</label>
      <input
        id="website"
        v-model="website"
        type="url"
        placeholder="e.g. https://jscodebox.com/"
      >
    </div>
    <div class="form-row">
      <label for="twitter">Twitter</label>
      <input
        id="twitter"
        v-model="twitter"
        type="url"
        placeholder="Your Twitter username (without https://twitter.com/...)"
      >
    </div>
    <div class="form-row">
      <label for="github">GitHub</label>
      <input
        id="github"
        v-model="github"
        type="url"
        placeholder="Your GitHub username (without https://github.com/...)"
      >
    </div>
    <div class="form-row">
      <label for="bio">Biography</label>
      <div class="double-area">
        <textarea
          id="bio"
          v-model="bio"
        />
        <span class="small-under">Briefly describe who you are and what you do.</span>
      </div>
    </div>
    <div class="row-group">
      <div class="form-row dir-col">
        <label for="password">New password</label>
        <input
          id="password"
          v-model="password"
          type="password"
        >
      </div>
      <div class="form-row">
        <label for="password_repeat">New password (repeat)</label>
        <div class="double-area">
          <input
            id="password_repeat"
            v-model="password_repeat"
            type="password"
          >
          <span class="small-under">Leave these two fields empty if you want to keep your old
            password.</span>
        </div>
      </div>
    </div>

    <h2>E-Mails</h2>
    <div v-if="verified_email">
      <div class="form-row">
        <label for="email_new_comments">New comments</label>
        <div class="double-area checkbox">
          <input
            id="email_new_comments"
            v-model="email_new_comments"
            type="checkbox"
          >
          <span class="small-under">You'll receive an email when someone posts a comment on a challenge
            where you've already posted a comment.</span>
        </div>
      </div>
      <div class="form-row">
        <label for="email_news">News</label>
        <div class="double-area checkbox">
          <input
            id="email_news"
            v-model="email_news"
            type="checkbox"
          >
          <span class="small-under">2-3 emails a year with news of JSCodebox (e.g. new challenges
            available)</span>
        </div>
      </div>
    </div>
    <p v-if="!verified_email">
      To receive emails you need to verify your email address. We've sent you an
      email with a confirmation link on sign up.<br>
      <i>If you've already confirmed your email addresse please click the 'Save'
        button and reload the page.</i>
    </p>

    <div
      class="btn btn-sm save-button"
      @click="save"
    >
      Save
    </div>
    <p
      v-if="alert"
      class="alert"
      :class="{
        green: alert.color == 'green',
        orange: alert.color == 'orange',
        red: alert.color == 'red',
      }"
      v-html="alert.msg"
    />
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';
export default {
  data() {
    return {
      user: {},
      verified_email: false,
      username: '',
      email: '',
      website: '',
      twitter: '',
      github: '',
      bio: '',
      alert: { msg: '', color: '' },
      password: '',
      password_repeat: '',
      email_new_comments: true,
      email_news: true,
    };
  },
  async created() {
    this.getUser();
  },
  methods: {
    getUser: async function () {
      this.user = this.$store.getters.getUser;
      // console.log(this.user);

      this.verified_email = this.user.verified_email;
      this.username = this.user.username;
      this.email = this.user.email;
      this.website = this.user.website;
      this.twitter = this.user.twitter;
      this.github = this.user.github;
      this.bio = this.user.bio;
      this.email_new_comments =
        this.user.mail_preferences &&
        this.user.mail_preferences.email_new_comments != undefined
          ? this.getMailPreferenceMapping(
              this.user.mail_preferences.email_new_comments
            )
          : true;
      this.email_news =
        this.user.mail_preferences &&
        this.user.mail_preferences.email_news != undefined
          ? this.getMailPreferenceMapping(this.user.mail_preferences.email_news)
          : true;
    },
    save: async function () {
      this.$store.dispatch('checkAuth');

      let mail_preferences = {
        email_new_comments: this.email_new_comments,
        email_news: this.email_news,
      };

      // save
      try {
        const response = await UserService.saveUser({
          email: this.email,
          website: this.website,
          twitter: this.twitter,
          github: this.github,
          bio: this.bio,
          password: this.password,
          password_repeat: this.password_repeat,
          mail_preferences: JSON.stringify(mail_preferences),
        });

        this.showAlert(response.msg, 'green');
        setTimeout(() => {
          this.removeAlert();
        }, 5000);

        // reset form data
        this.password = '';
        this.password_repeat = '';

        this.$store.dispatch('getUser');
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    getMailPreferenceMapping(value) {
      return value == 1;
    },
    showAlert: function (msg, color) {
      this.alert.msg = msg;
      this.alert.color = color;
    },
    removeAlert: function () {
      this.alert = { msg: '', color: '' };
    },
  },
};
</script>
<style lang="css" scoped>
.save-button {
  margin-top: 2rem;
}
</style>
