module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'firstFactorial';
    const params = {
      0: ['integer', 4, 6, 12, 20, 21],
    };
    const correct = [
      24, 720, 479001600, 2432902008176640000, 51090942171709440000,
    ];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
