<template>
  <div
    v-if="!verifiedEmail"
    class="verify-mail-container"
  >
    Please verify your email address. We've sent you a link with a confirmation
    link to <i>{{ usersEmail }}</i>.
  </div>
</template>
<script>
export default {
  data() {
    return {
      verifiedEmail: false,
      usersEmail: '',
    };
  },
  created() {
    // get newest user data
    this.$store.dispatch('getUser');

    this.verifiedEmail = this.$store.state.user.verified_email;
    this.usersEmail = this.$store.state.user.email;
  },
};
</script>
<style scoped>
.verify-mail-container {
  background-color: var(--light-grey);
  border: 3px solid var(--red);
  width: 100%;
  padding: 1rem;
  font-weight: 600;
  opacity: 0.8;
  text-align: center;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  margin-bottom: 2rem;
}
</style>
