<template>
  <div>
    <div class="form-row">
      <p>Experience (XP):</p>
      <div class="double-area">
        <p>
          {{ xp }}
        </p>
      </div>
    </div>
    <div class="form-row">
      <p>Level:</p>
      <div class="double-area">
        <p>
          {{ level }}
        </p>
        <span class="small-under">You are missing <b>{{ nextLevelBorder - xp }}</b> XP until the next
          level.</span>
      </div>
    </div>
    <div class="form-row">
      <p>Solved Challenges:</p>
      <p>{{ amountSolvedChallenges }}/{{ challenges.length }}</p>
    </div>
  </div>
</template>
<script>
import ChallengeService from '@/services/ChallengeService.js';
import XPService from '@/services/XPService.js';

export default {
  data() {
    return {
      challenges: {},
      userChallenges: {},
      amountSolvedChallenges: 0,
      xp: 0,
      level: 0,
      nextLevelBorder: 0,
    };
  },
  async created() {
    await XPService.load();

    // get challenges
    this.challenges = await ChallengeService.getChallenges();

    // get user
    const user = this.$store.getters.getUser;
    this.xp = user.xp;

    // get level
    this.level = XPService.getLevel(this.xp);

    // get next level border
    this.nextLevelBorder = XPService.getNextLevelBorder(this.xp);

    // get user challenges
    this.userChallenges = this.$store.getters.getAllUserChallenges;
    this.amountSolvedChallenges = this.userChallenges.filter(
      (c) => c.done
    ).length;
  },
};
</script>
