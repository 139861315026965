<template>
  <div>
    <div
      id="challenges"
      class="intro-challenge-selection"
    >
      <div class="by-topic">
        <div class="topic">
          <div class="description">
            <h3>Never programmed with JavaScript before?</h3>
            <p>
              <i>
                Start with this course and learn the fundamentals concepts of
                JavaScript</i>
            </p>
          </div>
          <router-link
            to="/challenge/declaring-and-initializing-variables"
            class="btn btn-sm bg-green"
            @click="clickNeverProgrammed"
          >
            Start
          </router-link>
        </div>
        <div class="topic">
          <div class="description">
            <h3>I want to improve my JavaScript skills</h3>
            <p>
              <i>Start with these simple challenges and then increase the
                level</i>
            </p>
          </div>
          <router-link
            to="/challenge/hello-world"
            class="btn btn-sm"
            @click="clickImproveSkills"
          >
            Start
          </router-link>
        </div>
        <div class="topic">
          <div class="description">
            <h3>I'm a JavaScript PRO, give me challenge!</h3>
            <p>
              <i>Try to solve our complex JavaScript challenges</i>
            </p>
          </div>
          <router-link
            to="/challenge/either404"
            class="btn btn-sm bg-red"
            @click="clickHardChallenges"
          >
            Start
          </router-link>
        </div>
        <div class="topic">
          <div class="description">
            <h3>I would like to choose the difficulty myself</h3>
            <p>
              <i>Then here is a list of all our challenges for you</i>
            </p>
          </div>
          <a
            v-smooth-scroll
            href="#challenge-list-container"
            class="btn btn-sm"
            @click="showChallengesList = true"
          >
            Show all challenges
          </a>
        </div>
      </div>
      <div class="by-category">
        <h3>...or solve challenges of a specific category</h3>
        <div class="category-list">
          <router-link
            v-for="category of categories"
            :key="category.id"
            :to="'/category/' + category.categoryPermalink"
            class="category"
          >
            #{{ category.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['categories', 'challenges'],
  data() {
    return {
      showChallengesList: false,
    };
  },
  methods: {
    clickNeverProgrammed() {
      window._paq.push([
        'trackEvent',
        'Landingpage',
        'Click First Challenge Button',
        'Never programmed',
      ]);
    },
    clickHardChallenges() {
      window._paq.push([
        'trackEvent',
        'Landingpage',
        'Click First Challenge Button',
        'Hard challenges',
      ]);
    },
    clickImproveSkills() {
      window._paq.push([
        'trackEvent',
        'Landingpage',
        'Click First Challenge Button',
        'Improve skills',
      ]);
    },
  },
};
</script>
<style scoped>
.intro-challenge-selection {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 4rem;
}
.intro-challenge-selection .by-topic {
  width: calc(66% - 0.5rem);
}

.intro-challenge-selection .by-category {
  width: calc(33% - 0.5rem);
  background-color: var(--light-grey);
  padding: 2rem 3rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}
.intro-challenge-selection .by-topic .topic {
  width: 100%;
  background-color: var(--light-grey);
  padding: 2rem 3rem;
  margin-bottom: 2rem;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}

.intro-challenge-selection .by-topic .topic h3 {
  margin: 0;
}

.intro-challenge-selection .category-list {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.intro-challenge-selection .by-category h3 {
  margin-bottom: 2rem;
  margin-top: 0;
}

.intro-challenge-selection .category-list p {
  margin-bottom: 2rem;
}

.intro-challenge-selection .category-list .category {
  border: 1px solid var(--primary);
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  margin: 0.2rem 0.4rem 0.2rem 0;
  padding: 5px 10px;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  font-size: 16px;
}
.intro-challenge-selection .category-list .category:hover {
  text-decoration: none;
  border-color: var(--grey);
  color: var(--grey);
}
</style>
