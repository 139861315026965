<template>
  <div>
    <div class="form-row">
      <label for="username">General Theme</label>
      <select
        v-model="theme"
        @change="changeTheme"
      >
        <option
          value="light"
          :selected="theme == 'light'"
        >
          Light Mode
        </option>
        <option
          value="dark"
          :selected="theme == 'dark'"
        >
          Dark Mode
        </option>
      </select>
    </div>
    <div class="form-row">
      <label for="username">Editor Theme</label>
      <select
        v-model="editor_theme"
        @change="changeEditorTheme"
      >
        <option
          v-for="(loopTheme, index) in editor_themes"
          :key="index"
          :value="loopTheme"
          :selected="loopTheme == theme"
        >
          {{ loopTheme }}
        </option>
      </select>
    </div>
    <div
      v-if="false"
      class="form-row"
    >
      <label for="username">Solution visibility</label>
      <div class="inner-rows">
        <div class="row-in-row">
          <input
            type="radio"
            disabled
            checked
          >
          <label for="">Visible</label>
        </div>
        <div class="row-in-row">
          <input
            type="radio"
            disabled
          >
          <label for="">Hidden</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from '@/config.js';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      theme: '',
      editor_theme: '',
      editor_themes: config.EDITOR_THEMES,
      toast: useToast(),
    };
  },
  mounted() {
    // set general theme
    if (this.$store.getters.getUserOption('general_theme')) {
      this.theme = this.$store.getters.getUserOption('general_theme');
    } else {
      this.theme = 'light';
    }

    // set editor theme
    let editorThemeFromStore =
      this.$store.getters.getUserOption('editor_theme');
    if (
      editorThemeFromStore &&
      this.editor_themes.indexOf(editorThemeFromStore) > -1
    ) {
      this.editor_theme = editorThemeFromStore;
    } else {
      this.editor_theme = config.DEFAULT_EDITOR_THEME;
    }
  },
  methods: {
    changeTheme() {
      this.$store.dispatch('setUserOption', {
        kex: 'general_theme',
        value: this.theme,
      });
      this.toast.clear();
      this.toast.success('Saved', { duration: 100000 });
    },
    changeEditorTheme() {
      this.$store.dispatch('setUserOption', {
        kex: 'editor_theme',
        value: this.editor_theme,
      });
      this.toast.clear();
      this.toast.success('Saved', { duration: 100000 });
    },
  },
};
</script>
<style lang="css" scoped></style>
