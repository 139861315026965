module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'greaterThan0';
    const params = {
      0: ['integer', 7, -3, 89, -12, -4, 1, 0],
    };
    const correct = [true, false, true, false, false, true, false];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
