module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'strangeWord';
    const params = {
      0: ['string', 'JSCodebox', 'JavaScript', 'Challenge', '', 'maoam'],
    };
    const correct = ['JCdbx', 'JvSrp', 'Calne', '', 'mom'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
