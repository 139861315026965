module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function (iframeDoc) {
    const functionName = 'hangman';
    const params = {
      0: [
        'string',
        'TestString',
        'Foobar',
        'Demo',
        'JS is fancy',
        'ThisIsAnFancyString',
      ],
    };
    const correct = [
      '_e_t_t_i_g',
      '_o_b_r',
      '_e_o',
      '_S_i_ _a_c_',
      '_h_s_s_n_a_c_S_r_n_',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
