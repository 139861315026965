<template>
  <div class="container profile-page form-page">
    <h1>
      Your profile
      <router-link
        :to="username"
        class="badge absolute"
      >
        View of other users
      </router-link>
    </h1>
    <div class="seperator">
      <h2>Stats</h2>
      <ProfileStats />
    </div>
    <div class="seperator">
      <h2>General settings</h2>
      <ProfileSettings />
    </div>

    <div class="seperator">
      <h2>Account</h2>
      <ProfileAccount />
    </div>

    <div class="seperator">
      <h2>Danger Zone</h2>
      <DangerZone :username="username" />
    </div>
  </div>
</template>
<script>
import ProfileStats from '@/components/profile/ProfileStats.vue';
import ProfileSettings from '@/components/profile/ProfileSettings.vue';
import ProfileAccount from '@/components/profile/ProfileAccount.vue';
import DangerZone from '@/components/profile/DangerZone.vue';

export default {
  name: 'ProfileView',
  components: {
    ProfileStats,
    ProfileSettings,
    ProfileAccount,
    DangerZone,
  },
  data() {
    return {
      username: '',
    };
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/');
    }

    this.username = this.$store.getters.getUser.username;
  },
};
</script>
<style lang="css">
.profile-page .seperator {
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--primary);
}
.profile-page .seperator:last-child {
  border-bottom: none;
}
.profile-page .badge {
  font-size: 30%;
  background-color: var(--grey);
  opacity: 0.6;
  color: var(--white);
  padding: 3px 5px;
  font-weight: 400;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
}
.profile-page .badge:hover {
  text-decoration: none;
  opacity: 1;
}
</style>
