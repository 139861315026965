<template>
  <div>
    <p>
      This option deletes your account and all data.
      <b>This action cannot be undone!</b>
    </p>
    <p>
      Type <b>{{ username }}</b> to confirm the deletion.
    </p>
    <input
      v-model="inputUsername"
      type="text"
      placeholder="Type to provided text"
    >
    <a
      class="btn btn-sm red"
      @click="delAccount"
    >Delete Account</a>
    <p
      v-if="alert"
      class="alert"
      :class="{
        green: alert.color == 'green',
        orange: alert.color == 'orange',
        red: alert.color == 'red',
      }"
      v-html="alert.msg"
    />
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';
export default {
  props: ['username'],
  data() {
    return {
      inputUsername: '',
      alert: { msg: '', color: '' },
    };
  },
  methods: {
    delAccount: async function() {
      if (window.confirm('Are you sure?')) {
        if (this.username === this.inputUsername) {
          UserService.deleteAccount().then(() => {
            this.$store.dispatch('logout');
          });
        } else {
          this.showAlert(
            'Please confirm the deletion by typing the text in the input field',
            'red'
          );
        }
      }
    },
    showAlert: function(msg, color) {
      this.alert.msg = msg;
      this.alert.color = color;
    },
    removeAlert: function() {
      this.alert = [];
    },
  },
};
</script>
<style lang="css" scoped></style>
