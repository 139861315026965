module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'theGround';
    const params = {
      0: [
        'object',
        { name: 'dirt', category: 'alpha' },
        { name: 'stone', category: 'alpha' },
        { name: 'slush', category: 'beta' },
        { name: 'quartz', category: 'gamma' },
        { name: 'water', category: 'delta' },
      ],
    };
    const correct = ['alpha', 'stone', 'slush', 'quartz', 'water'];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
