<template>
  <div
    id="app"
    :class="{
      'dark-mode': darkThemeActivated,
      'not-logged-in': !$store.getters.isLoggedIn && isHome,
    }"
  >
    <Header />

    <div class="router-view">
      <router-view />
    </div>

    <Footer />
  </div>
</template>
<script>
import Header from '@/components/app/Header.vue';
import Footer from '@/components/app/Footer.vue';

export default {
  components: { Header, Footer },
  computed: {
    darkThemeActivated() {
      return this.$store.getters.getUserOption('general_theme') == 'dark';
    },
    isHome() {
      return this.$route.path == '/';
    },
  },
};
</script>
<style>
.router-view {
  min-height: 67vh;
}
</style>
