<template>
  <div>
    <div class="container middle-form-container box-container">
      <div class="inner">
        <h1>Login to your account</h1>
        <input
          v-model="loginKey"
          type="text"
          placeholder="Username or email"
          autoFocus
        >
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          @keyup.enter="submit"
        >

        <div
          class="btn btn-sm"
          @click="submit"
        >
          Login
        </div>
        <span class="small-under-btn"><router-link to="/resetPassword">Forgot your password?</router-link></span>

        <p class="info">
          No account?
          <router-link to="/sign-up">
            Sign up here
          </router-link>!
        </p>

        <div
          v-if="alert"
          class="alert"
          :class="{
            red: alert.color == 'red',
            green: alert.color == 'green',
          }"
          v-html="alert.msg"
        />
      </div>
    </div>
    <!-- Ezoic - under_modal_windows - under_page_title -->
    <div id="ezoic-pub-ad-placeholder-111" />
    <!-- End Ezoic - under_modal_windows - under_page_title -->
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';

export default {
  name: 'LoginView',
  data() {
    return {
      loginKey: '',
      password: '',
      alert: { msg: '', color: '' },
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) this.$router.push('/');
  },
  methods: {
    async submit() {
      this.showAlert('Logging in...');
      try {
        const response = await UserService.login({
          loginKey: this.loginKey,
          password: this.password,
        });
        // console.log('login response', response);
        this.showAlert(response.msg, 'green');

        this.$store.dispatch('login', {
          token: response.token,
        });
        this.$router.push('/');
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    showAlert: function (msg, color = '') {
      this.alert.msg = msg;
      this.alert.color = color;
    },
  },
};
</script>
<style scoped>
.btn {
  margin-top: 1rem;
}
</style>
