<template>
  <header>
    <div class="inner">
      <div class="container flex-header">
        <div class="left">
          <router-link
            to="/"
            class="fake-logo"
            title="Home"
          >
            <img
              v-if="!$store.getters.isLoggedIn && isHome"
              src="@/assets/img/jscodebox-logo-white.png"
              alt="JSCodebox Logo"
            ><img
              v-if="$store.getters.isLoggedIn || !isHome"
              src="@/assets/img/jscodebox-logo.png"
              alt="JSCodebox Logo"
            >
          </router-link>
        </div>
        <div class="right">
          <div class="mr-3">
            <router-link
              to="/"
              title="All Challenges"
            >
              All Challenges
            </router-link>
            <router-link
              to="/ranking"
              title="Ranking"
            >
              Ranking
            </router-link>
            <router-link
              to="/about"
              title="About"
            >
              About
            </router-link>
          </div>

          <div class="profile-area">
            <router-link
              v-if="!$store.getters.isLoggedIn"
              to="/login"
              title="Sign in"
            >
              Sign in
            </router-link>
            <router-link
              v-if="!$store.getters.isLoggedIn"
              to="/sign-up"
              class="outline"
              title="Sign up"
            >
              Sign up
            </router-link>

            <router-link
              v-if="$store.getters.isLoggedIn"
              to="/profile/settings"
              title="Your profile"
              class="profile-link"
            >
              <img src="@/assets/img/user.svg">
            </router-link>
            <span
              v-if="$store.getters.isLoggedIn"
              class="logout"
              @click="logout"
            >Logout</span>
          </div>
        </div>
      </div>
    </div>
    <HeaderNotification />
  </header>
</template>
<script>
import HeaderNotification from './HeaderNotification.vue';

export default {
  components: {
    HeaderNotification,
  },
  computed: {
    isHome() {
      return this.$route.path == '/';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>
<style lang="css" scoped>
header {
  width: 100%;
  margin-bottom: 2.5rem;
}
#app.not-logged-in header {
  background-color: var(--primary);
}
header .inner {
  padding: 1rem 0;
}
header .flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .left {
  line-height: 0;
}
header .right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

header .right .profile-area {
  display: flex;
  align-items: center;
}
header .right a {
  margin: 0.2rem 0.7rem;
}
header .right a:last-child {
  margin-right: 0;
}
header .logout {
  color: var(--primary);
  font-size: 80%;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
}
#app.not-logged-in header .logout {
  color: var(--white);
}
header .logout:hover {
  opacity: 1;
}
header .profile-link {
  border: 1px solid var(--primary);
  color: var(--primary);
  opacity: 0.8;
  transition: 0.2s ease background-color;
  -webkit-transition: 0.2s ease background-color;
  -moz-transition: 0.2s ease background-color;
  -ms-transition: 0.2s ease background-color;
  -o-transition: 0.2s ease background-color;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}
header .profile-link:hover {
  background-color: var(--light-grey);
}
header .profile-link img {
  width: 1rem;
  padding: 0px 10px 0px 10px;
  box-sizing: content-box;
}
header a {
  color: var(--primary);
  opacity: 0.8;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  font-size: 1.2em;
  border-bottom: 3px solid transparent;
  padding-bottom: 3px;
  padding-top: 5px;
}
#app.not-logged-in header a {
  color: var(--white);
}
header a.outline {
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
}
header a:not(.fake-logo, .outline, .profile-link).router-link-exact-active,
header a:not(.fake-logo, .outline, .profile-link):hover {
  text-decoration: none;
  border-bottom: 3px solid var(--primary);
}
#app.not-logged-in
  header
  a:not(.fake-logo, .outline, .profile-link).router-link-exact-active,
#app.not-logged-in header a:not(.fake-logo, .outline, .profile-link):hover {
  border-color: var(--white);
}
header a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}
header a.filled {
  color: var(--primary);
  background-color: var(--white);
  padding: 2px 8px;
}
#app.not-logged-in header a.filled {
  color: var(--white);
}
header a.outline {
  border: 1px solid var(--primary);
  padding: 2px 8px;
}
#app.not-logged-in header a.outline {
  border-color: var(--white);
}
header a:not(.fake-logo).outline:hover,
header a:not(.fake-logo).outline.router-link-exact-active {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--white);
  text-decoration: none;
}
header a.fake-logo {
  font-size: 1.6em;
  font-weight: bold;
}
header a.fake-logo img {
  width: 16rem;
}
</style>
