<template>
  <div class="container">
    <h1>Imprint</h1>

    <h2>Angaben gemäß § 5 TMG</h2>

    <p>
      <b>Dev LH</b><br>Lorenz Hohmann<br>Am Birkicht 5<br>36145 Hofbieber
    </p>

    <h2>Kontakt</h2>

    <p>
      E-Mail:&nbsp;<a href="mailto:info@jscodebox.com">info@jscodebox.com</a>
    </p>

    <h2>Haftung für Inhalte</h2>

    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den<br>allgemeinen Gesetzen verantwortlich. Nach §§ 8
      bis 10 TMG sind wir als Diensteanbieter jedoch nicht<br>verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen<br>zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.
    </p>

    <p>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
      nach den allgemeinen<br>Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der<br>Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden<br>Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
    </p>

    <h2>Haftung für Links</h2>

    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren
      Inhalte wir keinen Einfluss haben.<br>Deshalb können wir für diese
      fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der<br>verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten<br>Seiten wurden zum Zeitpunkt der
      Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige<br>Inhalte
      waren zum Zeitpunkt der Verlinkung nicht erkennbar.
    </p>

    <p>
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
      ohne konkrete Anhaltspunkte einer<br>Rechtsverletzung nicht zumutbar.
      Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links<br>umgehend
      entfernen.
    </p>

    <h2>Urheberrecht</h2>

    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen<br>Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der<br>Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw.<br>Erstellers. Downloads und Kopien dieser Seite sind nur
      für den privaten, nicht kommerziellen Gebrauch<br>gestattet.
    </p>

    <p>
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
      werden die Urheberrechte Dritter<br>beachtet. Insbesondere werden
      Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
      eine<br>Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei<br>Bekanntwerden von Rechtsverletzungen
      werden wir derartige Inhalte umgehend entfernen.
    </p>

    <p>
      Quelle:&nbsp;<a
        href="https://www.e-recht24.de/"
        target="_blank"
        rel="noreferrer noopener"
      >eRecht24</a>
    </p>
  </div>
</template>
