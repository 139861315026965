module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'bugFix';
    const params = {
      0: ['string', 'xxbugYYX', 'bug', 'xxbugXYYy', 'bugXYYxX', 'xxxYYYzzyx'],
    };
    const correct = [
      'xxflowerYYX',
      'flower',
      'xxflowerXYYy',
      'flowerXYYxX',
      'xxxYYYzzyx',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
