<template>
  <div class="container middle-form-container box-container">
    <div class="inner">
      <h1>Reset password</h1>

      <div v-if="action == 'sendPasswordLink'">
        <input
          v-if="!sentResetLink"
          v-model="email"
          type="text"
          required
          placeholder="Your e-mail address*"
          autoFocus
        >
        <div
          class="btn btn-sm"
          @click="sendPassowordLink"
        >
          Reset
        </div>
      </div>

      <div v-if="action == 'resetPassword'">
        <input
          v-model="password"
          type="password"
          placeholder="Password*"
        >
        <input
          v-model="password_repeat"
          type="password"
          placeholder="Password (repeat)*"
        >
        <div
          class="btn btn-sm"
          @click="reset"
        >
          Reset
        </div>
      </div>

      <div
        v-if="alert"
        class="alert"
        :class="{
          red: alert.color == 'red',
          green: alert.color == 'green',
        }"
        v-html="alert.msg"
      />
    </div>
  </div>
</template>
<script>
import UserService from '@/services/UserService.js';

export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      password: '',
      password_repeat: '',
      action: 'sendPasswordLink', // sendPasswordLink OR resetPassword OR empty string
      alert: { msg: '', color: '' },
      token: '',
    };
  },
  async created() {
    if (this.$store.getters.isLoggedIn) this.$router.push('/');

    // check if token exists on password reset link
    this.token = this.$route.params['token'];
    if (this.token) {
      this.action = 'resetPassword';
      try {
        await UserService.checkToken(this.token);
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
        this.action = '';
      }
    }
  },
  methods: {
    async sendPassowordLink() {
      this.showAlert('Please wait...');
      try {
        const response = await UserService.sendPasswordLink(this.email);
        this.showAlert(response.msg, 'green');
        this.action = '';
      } catch (err) {
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    async reset() {
      this.showAlert('Please wait...');
      try {
        const response = await UserService.resetPasswordByToken(
          this.token,
          this.password,
          this.password_repeat
        );
        this.showAlert(response.msg, 'green');
        this.action = '';
      } catch (err) {
        // console.log(err);
        this.showAlert(err.response.data.msg, 'red');
      }
    },
    showAlert: function(msg, color = '') {
      this.alert.msg = msg;
      this.alert.color = color;
    },
    removeAlert: function() {
      this.alert = [];
    },
  },
};
</script>
<style scoped>
.alert {
  margin-top: 1rem;
}
</style>
