module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'firstJSON';
    const params = {
      0: ['string', 'name', 'age', 'title', 'difficulty', 'credits'],
      1: ['string', 'JSCodebox', '20', 'Nice title!', '42', '404'],
    };
    const correct = [
      '{"name":"JSCodebox"}',
      '{"age":"20"}',
      '{"title":"Nice title!"}',
      '{"difficulty":"42"}',
      '{"credits":"404"}',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
