module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'maxSumSubsequence';
    const params = {
      0: [
        'array',
        [-2, 1, -3, 4, -1, 2, 1, -5, 4],
        [1, 2, 3, -2, 5],
        [-2, -1, -3, -4, -1, -2, -1, -5, -4],
        [1, 2, 3, 4, 5],
        [-1, -2, -3, -4, -5],
        [1, -2, 3, -4, 5],
      ],
    };
    const correct = [6, 9, -1, 15, -1, 5];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
