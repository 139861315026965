<template>
  <div class="congratulations-container">
    <h2>🎉 Congratulations! 🎉</h2>
    <div v-if="difficulty != 0">
      <p class="text-center">
        You finished all Challenges of the category
        <b
          :class="{
            'text-success': difficulty == 1,
            'text-warning': difficulty == 2,
            'text-danger': difficulty == 3,
          }"
        >{{ getCategoryName(difficulty) }}</b>!
      </p>
      <p class="text-center">
        Now you really deserve a break. <b>But don't forget:</b> there are more
        challenges waiting for you and new ones will be added regularly. 😋
      </p>
      <p class="text-center">
        <a
          class="btn btn-sm"
          target="_blank"
          :href="
            'https://twitter.com/intent/tweet?text=' +
              encodeURIComponent(
                'I have successfully mastered all #JavaScript Challenges of the category ' +
                  getCategoryName(difficulty) +
                  ' on #JSCodebox! ‐ https://jscodebox.com/ @JSCodebox'
              )
          "
        >
          Share your success on Twitter</a>
      </p>
    </div>
    <div v-if="difficulty == 0">
      <p class="text-center">
        You have successfully completed all the
        <b>JavaScript Fundamentals</b> challenges! That's a great achievement
        that you made it this far!
      </p>
      <p class="text-center">
        Now it's time to take a deep breath and move on to the
        <router-link to="/challenge/hello-world">
          next challenges
        </router-link>
        to deepen the basics.
      </p>
      <p class="text-center">
        <i>Don't forget to
          <router-link to="/sign-up">create an account</router-link> to sync
          your challenges over multiple devices.</i>
      </p>
      <p class="text-center">
        <a
          class="btn btn-sm"
          target="_blank"
          :href="
            'https://twitter.com/intent/tweet?text=' +
              encodeURIComponent(
                'I have successfully mastered all #JavaScript Fundamental Challenges on #JSCodebox! ‐ https://jscodebox.com/ @JSCodebox'
              )
          "
        >
          Share your success on Twitter</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CategoryFinished',
  props: ['difficulty'],
  methods: {
    getCategoryName(difficulty) {
      if (difficulty == 1) return 'EASY';
      if (difficulty == 2) return 'MEDIUM';
      if (difficulty == 3) return 'HARD';
    },
  },
};
</script>
<style scoped lang="css">
.congratulations-container {
  background-color: var(--light-grey);
  padding: 1rem 2rem;
  margin-bottom: 5rem;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  border: 10px solid var(--primary);
  box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
}
.congratulations-container h2 {
  margin-top: 0;
  font-size: 3em;
  text-align: center;
}
.btn {
  margin-top: 1rem;
  font-size: 1em !important;
}
</style>
