module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'checkPassword';
    const params = {
      0: [
        'string',
        'omvdsse',
        'HUIFDSJHKSADN',
        'YY&glk4Hfi_ffS',
        'Joifd$3',
        'JoFDd$0MY6Ad4',
        'JoFDd#0MY6Ad4',
        'EY8J62',
        'UR9G1GOF',
        '6BKYT2GY8CX7CFPC',
        'JIOfdsf&fdsflk',
        'kg4N%sPrX$H',
        'ono53a7suikzb3h7',
        'qn%5F',
        '0&J&xxQYmD§kx§k$AO',
        '0&J&xxQYmD§kx§k$AO',
        '0#J#xxQYmD#kx#k#AO',
      ],
      1: [
        'string',
        'omvdsse',
        'HUIFDSJHKSADN',
        'YY&glk4Hfi_ffS',
        'Joifd$3',
        'JoFDd$0MY6Ad4',
        'JoFDd#0MY6Ad4',
        'EY8J62',
        'UR9G1GOF',
        '6BKYT2GY8CX7CFPC',
        'JIOfdsf&fdsflk',
        'kg4N%sPrX$H',
        'ono53a7suikzb3h7',
        'qn%5F',
        '0&J&xxQYmD§kx§k$AO',
        '0&J&xxQYmD§kx§k$Ao',
        '0#J#xxQYmD#kx#k#AO',
      ],
    };
    const correct = [
      false,
      false,
      true,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      true,
      false,
      false,
      true,
      false,
      false,
    ];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
