import { createRouter, createWebHistory } from 'vue-router';

import EmptyRouterView from './components/EmptyRouterView.vue';

import ChallengeView from './views/ChallengeView.vue';
import ChallengeList from './views/ChallengeList.vue';
import CategoryView from './views/CategoryView.vue';

import AboutView from './views/utils/AboutView.vue';
import BugFound from './views/utils/BugFound.vue';
import SubmitChallenge from './views/utils/SubmitChallenge.vue';
import PrivacyView from './views/utils/PrivacyView.vue';
import ImprintView from './views/utils/ImprintView.vue';

import NotFound from './views/NotFound.vue';

import LoginView from './views/LoginView.vue';
import SignUp from './views/SignUp.vue';
import ResetPassword from './views/ResetPassword.vue';

import ProfileView from './views/ProfileView.vue';
import MailConfirmation from './views/MailConfirmation.vue';
import UserProfile from './views/UserProfile.vue';
import RankingView from './views/RankingView.vue';

import AddChallenge from './views/admin/AddChallenge.vue';

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/ranking',
      meta: {
        title: 'Ranking ‒ JSCodebox',
      },
      name: 'ranking',
      component: RankingView,
    },
    {
      path: '/about',
      meta: {
        title: 'About ‒ JSCodebox',
      },
      name: 'about',
      component: AboutView,
    },
    {
      path: '/404',
      meta: {
        title: '404 ‒ JSCodebox',
      },
      name: 'notfound',
      component: NotFound,
    },
    {
      path: '/imprint',
      meta: {
        title: 'Imprint ‒ JSCodebox',
      },
      name: 'imprint',
      component: ImprintView,
    },
    {
      path: '/privacy',
      meta: {
        title: 'Privacy ‒ JSCodebox',
      },
      name: 'privacy',
      component: PrivacyView,
    },
    {
      path: '/bug-report',
      meta: {
        title: 'Bug Report ‒ JSCodebox',
      },
      name: 'bug-report',
      component: BugFound,
    },
    {
      path: '/submit-challenge',
      meta: {
        title: 'Submit your challenge ‒ JSCodebox',
      },
      name: 'submit-challenge',
      component: SubmitChallenge,
    },
    {
      path: '/login',
      meta: {
        title: 'Login ‒ JSCodebox',
      },
      name: 'login',
      component: LoginView,
    },
    {
      path: '/sign-up',
      meta: {
        title: 'Sign up ‒ JSCodebox',
      },
      name: 'sign-up',
      component: SignUp,
    },
    {
      path: '/resetPassword/:token?',
      meta: {
        title: 'Reset password ‒ JSCodebox',
      },
      props: true,
      name: 'resetPassword',
      component: ResetPassword,
    },
    {
      path: '/admin',
      component: EmptyRouterView,
      children: [
        { path: '', redirect: '/' },
        {
          path: 'addChallenge',
          meta: {
            title: 'Admin | Add challenge ‒ JSCodebox',
          },
          name: 'addchallenge',
          component: AddChallenge,
        },
      ],
    },
    {
      path: '/profile',
      component: EmptyRouterView,
      children: [
        { path: '', redirect: '/profile/settings' },
        {
          path: 'settings',
          name: 'profilesettings',
          meta: {
            title: 'Your profile ‒ JSCodebox',
          },
          props: true,
          component: ProfileView,
        },
        {
          path: 'confirmEmail/:token',
          meta: {
            title: 'Mail confirmation ‒ JSCodebox',
          },
          props: true,
          component: MailConfirmation,
        },
        {
          path: ':username',
          name: 'userprofile',
          props: true,
          component: UserProfile,
        },
      ],
    },
    {
      path: '/challenge',
      meta: {
        title: 'Challenge ‒ JSCodebox',
      },
      component: EmptyRouterView,
      children: [
        {
          path: 'list',
          redirect: '/',
        },
        {
          path: ':permalink',
          meta: {
            title: 'Challenge ‒ JSCodebox',
          },
          name: 'challenge',
          props: true,
          component: ChallengeView,
        },
      ],
    },
    {
      path: '/',
      meta: {
        title: 'Free JavaScript Challenges online ‒ JSCodebox',
      },
      name: 'challengelist',
      component: ChallengeList,
      props: (route) => ({ query: route.query.q }),
    },
    {
      name: 'CategoryView',
      path: '/category/:category_slug',
      component: CategoryView,
      props: true,
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/404',
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
