module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'lastButNotLeast';
    const params = {
      0: ['integer', 37, 84, 42, 19, 1],
      1: ['integer', 17, 23, 19, 17, 51],
      2: ['integer', 19, 54, 96, 38, 21],
    };
    const correct = [true, true, false, false, true];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
