module.exports = {
  SETTINGS: {
    EXEC_CODE: false,
    HAS_TEST_CASES: false,
  },
  solve: function(iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.innerHTML =
        `function solve() {\n ${jsCode}` +
        '\nif(typeof a === \'undefined\' || typeof b === \'undefined\' || typeof c === \'undefined\') return false;\n' +
        'document.write("<p id=\'res-a\' style=\'display:none\'>" + a + "</p>");\n' +
        'document.write("<p id=\'res-b\' style=\'display:none\'>" + b + "</p>");\n' +
        'document.write("<p id=\'res-c\' style=\'display:none\'>" + c + "</p>");\n' +
        '}\n' +
        'solve()';
      iframeDoc.head.appendChild(script);

      resolve(
        iframeDoc.getElementById('res-a') &&
          iframeDoc.getElementById('res-a').innerHTML == '4' &&
          iframeDoc.getElementById('res-b') &&
          iframeDoc.getElementById('res-b').innerHTML == '2' &&
          iframeDoc.getElementById('res-c') &&
          iframeDoc.getElementById('res-c').innerHTML == '6'
      );
    });
  },
};
