module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'is42';
    const params = {
      0: ['integer', 23, 42, 42, 12, 2],
      1: ['integer', 21, 0, 42, 30, 41],
    };
    const correct = [false, true, true, true, false];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
