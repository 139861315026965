module.exports = {
  /**
   * Writes the head with necessary test stuff!
   * Important: It overrides existing content because of doc.write()
   */
  writePredefinedHead(iframeDoc) {
    // import styles
    iframeDoc.write(
      '<style>*{font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif}body{margin:0}h1{margin: 5px 0;padding: 0 10px;}p.console-error{margin:3px 0;background-color:#ac1a2b;color:#fff;padding:3px 8px}p.console-log{margin:3px 0;background-color:#29997e;padding:3px 8px}p.console-solve:first-child::after{content:none}p.console-solve::after{content:\'✖\';color:#FFF;transform: translateY(-50%);padding: 3px 4px;font-size: 12px;line-height: 12px;display:block;position:absolute;border-radius:5px;background-color:#ac1a2b;top:50%;right:1rem}p.console-solve.valid::after{content:\'✔\';background-color:#118d6f;}p.console-solve{display:flex;align-items:center;margin:2px 0;background-color:#efefef;padding:8px 2px;position:relative}p.console-solve span{min-width:25%;text-align:center;word-break:break-all;padding:0 5px}p.console-solve span.tryCase{text-align: left;line-height:17px;max-width:40%;flex-grow:1;}p.console-solve span.correct{margin-right:2rem;}input{margin:5px 10px; padding: 5px;width:calc(100% - 20px);}</style>'
    );

    // error handling
    iframeDoc.write(
      '<script>' +
        'window.onerror = function(msg, url, linenumber) {' +
        '  let existingErrors = document.getElementsByClassName(\'console-error\').length;' +
        '  if(existingErrors) return;' + // dont show error message if an error message already exists in the frame`
        '  document.write(\'<p class=\"console-error\">\' + msg + \' on line: \' + linenumber + \'</p>\');' +
        '}' +
        '<\/script>'
    );

    // handle console outputs
    let handleConsoleOutputFunction =
      '<script>var console = {' +
      '  solveMessage: function(tryCase, functionResult, correct) {' +
      '    let result = "";' +
      '' +
      '    // opening part\n' +
      '    result += (Array.isArray(functionResult) ? \'[\' : \'\');' +
      '' +
      '    // middle part\n' +
      '    result += (functionResult != \'<b>Your output</b>\' && typeof functionResult == \'string\' ? \'&apos;\' : \'\'); ' + // print pre functionResult
      '' +
      '    // function result\n' +
      '    if(typeof functionResult == \'object\' && !Array.isArray(functionResult)) {' +
      '      result += JSON.stringify(functionResult).replaceAll(\',\', \',<br>\');' +
      '    } else if (Array.isArray(functionResult) && Array.isArray(functionResult[0])) {' +
      '      let innerRes = "";' +
      '      for(let i = 0; i < functionResult.length; i++) {' +
      '        innerRes += \'[\' + functionResult[i] + \'],\'' +
      '      }' +
      '      innerRes = innerRes.substring(0, innerRes.length - 1);' +
      '      result += innerRes;' +
      '    } else if(functionResult == undefined) {' +
      '      result += \'<i>undefined</i>\'' +
      '    } else if(Array.isArray(functionResult)) {' +
      '      let innerResType = correct[0] == \'[\' && correct[1] == "\'" ? \'string\' : \'unknown\';' +
      '      if(innerResType == \'string\') {' +
      '        let innerRes = "";' +
      '        for(let i = 0; i < functionResult.length; i++) {' +
      '          innerRes += "\'" + functionResult[i] + "\',"' +
      '        }' +
      '        innerRes = innerRes.substring(0, innerRes.length - 1);' +
      '        result += innerRes;' +
      '      } else {' +
      '        result += functionResult' +
      '      }' +
      '    } else {' +
      '      result += functionResult;' +
      '    }' +
      '' +
      '    // closing part\n' +
      '    result += (functionResult != \'<b>Your output</b>\' && typeof functionResult == \'string\' ? \'&apos;\' : \'\') + (Array.isArray(functionResult) ? \']\' : \'\');' + // print after functionResult
      '' +
      '    let validClass = result.toString().replaceAll(\'&apos;\', \'\') == correct.toString().replaceAll("&apos;", "") ? \'valid\' : \'\';' +
      '' +
      '    document.write(' +
      '      "<p class=\'console-solve " + validClass + "\'>' +
      '      <span class=\'tryCase\'>" + tryCase + "</span>' +
      '      <span class=\'result\'>" + result + "</span>' +
      '      <span class=\'correct\'>" + correct + "</span>' +
      '      </p>"' +
      '    );' +
      '' +
      '  },' +
      '' +
      '  log: function(...args) {' +
      '    document.write("<p class=\'console-log\'>" + args.map(a => (typeof a == \'string\' ? a : JSON.stringify(a))) + "</p>");' +
      '  }' +
      '};' +
      '</script>';
    iframeDoc.write(handleConsoleOutputFunction);
  },
};
