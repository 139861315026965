module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'outsideIn';
    const params = {
      0: [
        'array',
        [0, 6, 3, 7, 2],
        [1],
        [0, 1],
        [4, 1, 6, 1, 5, 9],
        [1, 8, 3, 6, 1],
        [0, 1, 0],
      ],
      1: [
        'array',
        [2, 6, 3, 0, 7],
        [0, 1],
        [1],
        [4, 1, 5, 9, 3],
        [8, 3, 6, 1],
        [1, 0, 1],
      ],
    };
    const correct = [true, false, true, false, true, true];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
