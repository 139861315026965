module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'longestWord';
    const params = {
      0: [
        'array',
        ['\'Welcome\'', '\'to\'', '\'JSCodebox\''],
        ['\'You\'', '\'are\'', '\'great\''],
        ['\'You\'', '\'are\'', '\'very\'', '\'beautiful\''],
        ['\'Welcome\'', '\'to\'', '\'jscodebox.com\''],
        ['\'This\'', '\'is\'', '\'a\'', '\'test\''],
      ],
    };
    const correct = [
      'JSCodebox',
      'great',
      'beautiful',
      'jscodebox.com',
      'Foobar',
    ];
    const expectedType = ['string'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
