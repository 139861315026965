module.exports = {
  solve: function(iframeDoc, jsCode, bodyContent) {
    return new Promise((resolve, reject) => {
      setTimeout(function() {
        let script;

        // test case 1
        iframeDoc.getElementById('firstname').value = 'Jane';
        iframeDoc.getElementById('lastname').value = 'Doe';
        script = document.createElement('script');
        script.innerHTML = jsCode + 'whatIsMyName();';
        iframeDoc.head.lastChild.remove();
        iframeDoc.head.appendChild(script);
        let case1Result =
          iframeDoc.getElementById('fullname').value == 'Jane Doe';

        // test case 2
        iframeDoc.getElementById('firstname').value = 'Jane';
        iframeDoc.getElementById('lastname').value = '';
        script = document.createElement('script');
        script.innerHTML = jsCode + 'whatIsMyName();';
        iframeDoc.head.lastChild.remove();
        iframeDoc.head.appendChild(script);
        let case2Result =
          iframeDoc.getElementById('fullname').value == 'Jane #';

        // test case 3
        iframeDoc.getElementById('firstname').value = 'Jane';
        iframeDoc.getElementById('lastname').value = '';
        script = document.createElement('script');
        script.innerHTML = jsCode + 'whatIsMyName();';
        iframeDoc.head.lastChild.remove();
        iframeDoc.head.appendChild(script);
        let case3Result =
          iframeDoc.getElementById('fullname').value == 'Jane #';

        // test
        resolve(case1Result && case2Result && case3Result);

        // cleanup
        iframeDoc.getElementById('firstname').value = '';
        iframeDoc.getElementById('lastname').value = '';
        iframeDoc.getElementById('fullname').value = '';
      }, 0);
    });
  },
  getTestCases: function() {
    return {
      type: 'function',
      payload: {
        functionName: 'whatIsMyName',
        functionBehavior: 'NO_EXECUTION',
      },
    };
  },
  getChallengePrepareCode: function() {
    return '<input placeholder="First name" id="firstname"><input placeholder="Last name" id="lastname"><input placeholder="Full name" id="fullname" disabled>';
  },
};
