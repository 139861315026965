<template>
  <div
    v-if="notification"
    class="header-notification"
    :class="'color-' + notification.color"
  >
    <div
      class="text"
      v-html="notification.text"
    />
    <span
      title="Dismiss"
      class="dismiss"
      @click="dismiss"
    >✖</span>
  </div>
</template>
<script>
import EssentialService from '@/services/EssentialService.js';

export default {
  data() {
    return {
      notification: undefined,
    };
  },
  async mounted() {
    let hintFromDB = await EssentialService.getHeaderNotification();
    let dismissedHints = localStorage.getItem('dismissedHints');

    // only if user already hasn't dismissed this notification
    if (
      !dismissedHints ||
      JSON.parse(dismissedHints).indexOf(hintFromDB.id) == -1
    ) {
      this.notification = hintFromDB;
    }
  },
  methods: {
    dismiss() {
      let dismissedHints = localStorage.getItem('dismissedHints')
        ? JSON.parse(localStorage.getItem('dismissedHints'))
        : [];
      dismissedHints.push(this.notification.id);
      localStorage.setItem('dismissedHints', JSON.stringify(dismissedHints));
      this.notification = undefined;

      window._paq.push([
        'trackEvent',
        'HeaderNotification',
        'Dismiss hint',
        '',
      ]);

      // console.log(localStorage);
    },
  },
};
</script>
<style lang="css">
.header-notification {
  padding: 5px 3rem;
  width: 100%;
  background: var(--white);
  border-bottom: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  text-align: center;
  position: relative;
}
.header-notification.color-primary {
  color: var(--white);
  background-color: var(--primary);
}
.header-notification.color-primary a {
  color: var(--white);
  text-decoration: underline;
}
.header-notification.color-red {
  color: var(--white);
  background-color: var(--red);
}
.header-notification.color-red a {
  color: var(--white);
  text-decoration: underline;
}
.header-notification.color-green {
  color: var(--white);
  background-color: var(--green);
}
.header-notification.color-green a {
  color: var(--white);
  text-decoration: underline;
}
.header-notification .text,
.header-notification .text a {
  line-height: 24px;
  font-size: 16px;
}
.header-notification .dismiss {
  position: absolute;
  right: 2rem;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
</style>
