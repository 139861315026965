import axios from 'axios';

const url = '/api/challenge/';

export default {
  getChallenges() {
    return axios.get(url).then((response) => response.data);
  },
  getChallengeById(id) {
    return axios.get(url + '/byId/' + id).then((response) => response.data);
  },
  getChallengeByPermalink(permalink) {
    return axios
      .get(url + '/byPermalink/' + permalink)
      .then((response) => response.data);
  },
  getChallengesByDifficulty(difficulty) {
    return axios
      .get(url + 'byDifficulty/' + difficulty)
      .then((response) => response.data);
  },
  getChallengesByCategory(categoryID) {
    return axios
      .get(url + 'byCategory/' + categoryID)
      .then((response) => response.data);
  },
  getLastChallenges(challengeID) {
    return axios
      .get(url + 'last/' + challengeID)
      .then((response) => response.data);
  },
  getComments(challengeID) {
    return axios
      .get(url + 'comment/' + challengeID)
      .then((response) => response.data);
  },
  addComment(data) {
    return axios.post(url + 'comment', data).then((response) => response.data);
  },
  getChallengeCountings() {
    return axios.get(url + 'countings').then((response) => response.data);
  },
};
