import axios from 'axios';

const url = '/api/ranking/';

export default {
  getXP() {
    return axios
      .get(url + 'xp')
      .then((response) => response)
      .catch((err) => err.response);
  },
  getXPRank() {
    return axios
      .get(url + 'xp/ownRank')
      .then((response) => response)
      .catch((err) => err.response);
  },
  getComments() {
    return axios
      .get(url + 'comments')
      .then((response) => response)
      .catch((err) => err.response);
  },
  getChallenges() {
    return axios
      .get(url + 'challenges')
      .then((response) => response)
      .catch((err) => err.response);
  },
  getChallengeRank() {
    return axios
      .get(url + 'challenges/ownRank')
      .then((response) => response)
      .catch((err) => err.response);
  },
};
