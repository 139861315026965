module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'longest';
    const params = {
      0: [
        'string',
        'aaBBBBcDDee',
        '',
        'aaBBcDDDDDDeeFFFFFFFFg',
        'a',
        'aBBBccD',
      ],
    };
    const correct = [4, 0, 8, 1, 3];
    const expectedType = ['integer'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
