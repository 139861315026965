<template>
  <div class="legend">
    <div class="entry">
      Test Cases
    </div>
    <div class="entry green">
      Console Logs
    </div>
    <div class="entry red">
      JS Errors
    </div>
  </div>
</template>
<script>
export default {
  name: 'CodeColorLegend',
};
</script>
<style scoped lang="css">
.legend {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 75%;
  opacity: 0.5;
  transition: 0.2s ease all;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
}
.legend:hover {
  opacity: 1;
}
.legend .entry {
  background-color: #efefef;
  padding: 3px 8px;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  -moz-border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}
.legend .entry.green {
  background-color: var(--green);
}
.legend .entry.red {
  background-color: var(--red);
  color: var(--white);
}
</style>
