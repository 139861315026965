<template>
  <div
    class="level-bar-wrapper"
    :class="{ loaded: !loading }"
  >
    <div id="cont">
      <svg
        id="svg"
        width="200"
        height="200"
        viewPort="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          :r="r"
          cx="100"
          cy="100"
          fill="transparent"
          stroke-dasharray="565.48"
          stroke-dashoffset="0"
        />
        <circle
          id="bar"
          :r="r"
          cx="100"
          cy="100"
          fill="transparent"
          stroke-dasharray="565.48"
          stroke-dashoffset="0"
          :style="'stroke-dashoffset:' + strokeDashOffset + 'px'"
        />
      </svg>
    </div>

    <!-- <progress-bar :options="progressBarOptions" :value="progressValue" /> -->
    <div class="label">
      <span class="small">Level </span>{{ level }}<br>
    </div>
  </div>
</template>
<script>
import XPService from '@/services/XPService.js';

export default {
  props: ['xp'],
  data() {
    return {
      loading: true,
      strokeDashOffset: 550,
      missingXP: 0,
      level: 0,
      nextBorder: 0,
      r: 90,
    };
  },
  created() {
    setTimeout(
      async function () {
        await XPService.load();

        // set progress value
        this.nextBorder = XPService.getNextLevelBorder(this.xp);

        this.missingXP = this.nextBorder - this.xp;

        this.level = XPService.getLevel(this.xp);

        this.calcStrokeDashOffset();

        this.loading = false;
      }.bind(this),
      100
    );
  },
  methods: {
    async calcStrokeDashOffset() {
      let preBorder = XPService.getPreLevelBorder(this.xp);
      let levelDiff = Math.abs(this.nextBorder - preBorder);
      let currentValue = this.xp - preBorder;
      let progressValue = (100 / levelDiff) * currentValue;

      let c = Math.PI * (this.r * 2);

      let percentage = ((100 - progressValue) / 100) * c;
      this.strokeDashOffset = percentage;
    },
  },
};
</script>
<style lang="css" scoped>
.level-bar-wrapper {
  position: relative;
  opacity: 0;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
}
.level-bar-wrapper.loaded {
  opacity: 1;
}
.level-bar-wrapper .label {
  text-align: center;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 25px;
  color: var(--grey);
  display: flex;
  flex-direction: column;
}
#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s ease-in-out;
  -webkit-transition: stroke-dashoffset 1s ease-in-out;
  -moz-transition: stroke-dashoffset 1s ease-in-out;
  -ms-transition: stroke-dashoffset 1s ease-in-out;
  -o-transition: stroke-dashoffset 1s ease-in-out;
  stroke: var(--light-grey);
  stroke-width: 0.7em;
}
#svg #bar {
  stroke: var(--primary);
}
#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  position: relative;
  transform: scale(0.75);
}
</style>
