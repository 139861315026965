<template>
  <div class="challenge-infos">
    <div class="info-menu">
      <p
        :class="{ active: view == 'challenge' }"
        @click="changeTab('challenge')"
      >
        Challenge 📝
      </p>
      <p
        v-if="resources && resources.length"
        :class="{ active: view == 'resources' }"
        @click="changeTab('resources')"
      >
        Resources 📚
      </p>
      <p
        v-if="hint && showHint"
        :class="{ active: view == 'hint', highlight: true }"
        @click="changeTab('hint')"
      >
        Hints 💡
      </p>
      <p
        v-if="false"
        :class="{ active: view == 'comments' }"
        @click="changeTab('comments')"
      >
        Comments 💬
      </p>
    </div>

    <div
      v-if="view == 'challenge'"
      class="challenge-info-container"
    >
      <div class="challenge-text">
        <p v-html="description" />
        <div
          v-if="example"
          class="example-container"
        >
          <p><b>Examples:</b></p>
          <p
            class="example"
            v-html="$options.filters.exampleNl2Br(example)"
          />
        </div>
      </div>
      <div class="challenge-infos">
        <p>
          <router-link
            :to="'/category/' + category.permalink"
            title="Show all challenges in this category"
            class="badge"
          >
            #{{ category.name }}
          </router-link>
        </p>
        <p
          class="difficulty"
          title="Difficulty"
        >
          <span
            v-if="difficulty === '1'"
            class="bg-green badge no-click"
          >Easy</span>
          <span
            v-if="difficulty === '2'"
            class="bg-orange badge no-click"
          >Medium</span>
          <span
            v-if="difficulty === '3'"
            class="bg-red badge no-click"
          >Hard</span>
        </p>
        <p
          class="solve-state"
          title="Status"
        >
          <span
            v-if="done"
            class="bg-green badge no-click"
          >solved</span>
          <span
            v-if="!done"
            class="bg-red badge no-click"
          >unsolved</span>
        </p>
      </div>
    </div>
    <div v-if="view == 'resources'">
      <ul class="resources">
        <li
          v-for="(resource, index) in resources"
          :key="index"
        >
          <b>Resource #{{ index + 1 }}: </b>
          <a
            :href="resource.url"
            target="_blank"
          > {{ resource.text }}</a>
        </li>
      </ul>
      <!-- Ezoic - in_resources - incontent_5 -->
      <div id="ezoic-pub-ad-placeholder-107" />
      <!-- End Ezoic - in_resources - incontent_5 -->
    </div>
    <div
      v-if="view == 'hint'"
      class="hints"
    >
      <p v-html="hint" />
    </div>
    <p v-if="view == 'comments'">
      <CommentsComponent
        v-if="false"
        :challenge-i-d="challengeID"
      />
    </p>
  </div>
</template>
<script>
import CommentsComponent from '@/components/challenge/CommentsComponent.vue';
import HintToastComponent from '@/components/challenge/HintToastComponent.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'ChallengeInfos',
  components: {
    CommentsComponent,
  },
  filters: {
    exampleNl2Br(value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
  props: [
    'description',
    'example',
    'resources',
    'hint',
    'done',
    'challengeID',
    'category',
    'difficulty',
  ],
  data() {
    return {
      view: 'challenge',
      showHint: false,
      toast: useToast(),
      showHintTimeout: null,
    };
  },
  created() {
    // start countdown to show hint after 3 minutes
    let that = this;
    this.showHintTimeout = setTimeout(function () {
      if (that.hint) {
        that.showHint = true;
        that.toast.info({
          component: HintToastComponent,
          listeners: {
            showHintTabClick: () => {
              that.view = 'hint';
            },
          },
        });
      }
    }, 1000 * 300);
  },
  beforeUnmount() {
    clearTimeout(this.showHintTimeout);
  },
  methods: {
    changeTab(tabName) {
      this.view = tabName;
      window._paq.push(['trackEvent', 'Challenge', 'Change Tab', tabName]);
    },
  },
};
</script>
<style scoped lang="css">
.info-menu {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.info-menu p {
  padding: 7px 20px;
  border-right: 1px solid var(--grey);
  margin: 0;
  border-bottom: 3px solid transparent;
  transition: 0.2s ease all;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  cursor: pointer;
}
.info-menu p:hover {
  background-color: var(--grey);
}
.info-menu p.active {
  border-bottom-color: var(--primary);
}
.info-menu p:first-child {
  margin-left: 0;
}
.info-menu p:last-child {
  border-right: none;
}
.info-menu p.highlight {
  animation: highlight 2s ease;
}
@keyframes highlight {
  0% {
    color: var(--red);
    border-bottom: 3px solid var(--red);
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    color: var(--red);
    border-bottom: 3px solid var(--red);
    transform: scale(1);
  }
}
h3 {
  margin-top: 1rem;
}
.challenge-infos {
  margin-top: 1rem;
}
.example-container {
  margin-top: 2rem;
}
.example {
  border-left: 5px solid var(--primary);
  background-color: var(--light-grey);
  padding: 10px;
  font-family: monospace;
  font-size: 15px;
}
.resources {
  margin: 1.5rem 0 2rem 0;
  font-size: 18px;
}
.resources li {
  list-style: none;
}
.hints {
  margin: 1.5rem 0 2rem 0;
}
.challenge-info-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 2rem;
}

.challenge-info-container .challenge-text {
  max-width: 70%;
}

.challenge-info-container .challenge-infos p {
  margin: 0;
  text-align: center;
}

.challenge-info-container .challenge-infos {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
</style>
