module.exports = {
  solve: function (iframeDoc, jsCode, bodyContent) {
    return '%main-solver%';
  },
  getTestCases: function () {
    const functionName = 'checkPassword';
    const params = {
      0: [
        'string',
        'omvdsse',
        'gizzard-fiesta-dispel-disgorge-never-chisel',
        'HUIFDSJHKSADN',
        'JoFDd#0MY6Ad4',
        'gWb8reHTbuo3nbRp5EL625is',
        'JIOfdsf&fdsflk',
        'ono53a7suikzb3h7',
        'qn%5F',
        'qn%5F',
        'rVrsfheokazBnvcgRnoWMFeUxCqpZsNq',
        '0#J#xxQYmD#kx#k#AOK7',
      ],
      1: [
        'string',
        'omvdsse',
        'gizzard-fiesta-dispel-disgorge-never-chisel',
        'HUIFDSJHKSADN',
        'JoFDd#0MY6Ad4',
        'gWb8reHTbuo3nbRp5EL625is',
        'JIOfdsf&fdsflk',
        'ono53a7suikzb3h7',
        'qn%5F',
        'qn$5F',
        'rrsfheokazBnvcgRnoWMFeUxCqpZsNq',
        '0#J#xxQYmD#kx#k#AOK7',
      ],
    };
    const correct = [
      false,
      true,
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      true,
    ];
    const expectedType = ['boolean'];

    return {
      type: 'cases',
      payload: { functionName, params, correct, expectedType, output: true },
    };
  },
};
