<template>
  <div class="first-visit-screen">
    <div class="highlight-section">
      <div class="left">
        <h1>Welcome to JSCodebox!</h1>
        <h2>Free JavaScript Challenges</h2>
        <ul class="advantages">
          <li>Learn or improve your JavaScript skills</li>
          <li>Completely free</li>
          <li>No registration necessary</li>
          <li>Different levels of difficulty</li>
          <i style="padding-top: 2px">&nbsp;&nbsp;&nbsp;&nbsp;...and much more cool stuff!</i>
        </ul>
      </div>
      <div class="right">
        <img
          src="@/assets/img/jscodebox-demo-left.gif"
          :title="demoTitle"
          alt="Demo of a JSCodebox Challenge"
        >
        <img
          src="@/assets/img/jscodebox-demo-right.gif"
          :title="demoTitle"
          alt="Demo of a JSCodebox Challenge"
        >
      </div>
    </div>
    <div class="normal-section">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#3282B8"
          fill-opacity="1"
          d="M0,32L120,26.7C240,21,480,11,720,10.7C960,11,1200,21,1320,26.7L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
        />
      </svg>
      <a
        v-smooth-scroll
        href="#challenges"
        class="custom-btn"
        title="Start first Challenge"
      >
        <img
          src="@/assets/img/rocket-launch-lines.svg"
          alt="Start first Challenge"
        >
        <span @click="clickMainStart">Start now!</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      demoTitle: 'Demonstration of JSCodebox',
    };
  },
  methods: {
    clickMainStart() {
      window._paq.push([
        'trackEvent',
        'Landingpage',
        'Click Main Start Button',
        '',
      ]);
    },
  },
};
</script>
<style scoped>
.first-visit-screen .highlight-section {
  background-color: var(--primary);
  width: 100vw;
  height: 27rem;
  padding: 0 13vw;
  padding-top: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: -13vw;
  margin-top: -2.5rem;
}
.first-visit-screen .normal-section svg {
  position: absolute;
  margin-left: -13vw;
  margin-top: -4.1rem;
  z-index: -1;
}
.first-visit-screen .highlight-section img {
  height: 22vw;
}
.first-visit-screen .highlight-section .advantages {
  color: var(--white);
  list-style: none;
  font-size: 22px;
  padding-top: 2rem;
}
.first-visit-screen .highlight-section .advantages li {
  padding: 2px 0;
}
.first-visit-screen .highlight-section .advantages li::before {
  content: "\2714";
  margin-right: 7px;
  font-size: 25px;
  color: var(--secondary);
}

.first-visit-screen .highlight-section .right {
  margin-top: 4rem;
  width: 60%;
  display: flex;
  justify-content: end;
  animation: fadeIn 2s ease;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.first-visit-screen .left {
  width: 40%;
}
.first-visit-screen .normal-section {
  padding-top: 4rem;
  padding-bottom: 7rem;
}
.first-visit-screen h1 {
  color: var(--white);
  margin-bottom: 0;
}
.first-visit-screen h2 {
  color: var(--white);
  margin-top: 0;
  font-weight: normal;
}
.first-visit-screen .custom-btn {
  display: flex;
  background-color: var(--secondary);
  color: var(--white);
  padding: 1rem;
  justify-content: space-evenly;
  align-items: center;
  width: 12rem;
  border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  margin: 0 auto;
  transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
  -webkit-transition: 0.2s ease-in-out all;
  -moz-transition: 0.2s ease-in-out all;
  -ms-transition: 0.2s ease-in-out all;
  -o-transition: 0.2s ease-in-out all;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  margin-left: 6rem;
}
.first-visit-screen .custom-btn img {
  width: 2rem;
}
.first-visit-screen .custom-btn:hover {
  text-decoration: none;
  transform: scale(1.05);
}
</style>
